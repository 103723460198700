import {
  __,
  constants,
  countrySelectors,
  CURRENCY_CODES,
  date,
  DATE_FORMAT,
  ORDER_STATUS,
  orderSelectors,
  orderService,
  prodTypeSelectors,
  sellerWorkspaceService,
  utils,
} from 'common-services';
import { getCategoryText } from 'common-services/dist/parsers';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { getOrderSourceText, NO_TAG } from '../../../constants';
import FacetFilter from '../../atoms/FacetFilter';
import { FacetFilterOption, IAmountType, IFilterOption } from '../../atoms/FacetFilter/FacetFilter.component';
import * as S from './Facets.styled';

export interface IProps {
  catalogs: { [id: number]: IWorkspace };
  changeSearchState: (s: ISearchOrder, dateRange?: IDateRange, dateRangeDeliveryDate?: IDateRange) => void;
  className?: string;
  clearFilters: () => void;
  clients?: Array<IClient>;
  contacts: { [cId: number]: IContact };
  dateRange: string;
  dateRangeDeliveryDate?: string;
  facets: IFacets;
  facetsGlobal: IFacets;
  filterByDeliveryAt?: boolean;
  filtersBlacklist?: Array<string>;
  me: IUser;
  numHeaders: number; // For iPad with position absolute
  onHide?: (hide: boolean) => void;
  searchState: ISearchOrder;
  showAll?: boolean;
  showClosed?: boolean;
  type?: IOrderType;
  viewMode?: 'order' | 'delivery' | 'request' | 'import-job' | 'prepare-orders';
  showDeliveryRangeFilter?: boolean;
}

const Facets: React.FC<IProps> = ({
  catalogs,
  changeSearchState,
  className,
  clearFilters,
  clients,
  contacts,
  dateRange,
  dateRangeDeliveryDate,
  facets,
  facetsGlobal,
  filterByDeliveryAt,
  filtersBlacklist = [],
  me,
  numHeaders,
  onHide,
  searchState,
  showAll,
  showClosed,
  type,
  viewMode,
  showDeliveryRangeFilter = false,
}) => {
  const countries = useSelector(countrySelectors.getCountries);
  const prodTypes = useSelector(prodTypeSelectors.getProdTypes);
  const formats = useSelector(orderSelectors.getFormats());
  const [hidden, setHidden] = React.useState(showClosed);

  React.useEffect(() => {
    setHidden(showClosed);
  }, [setHidden, showClosed]);
  return (
    <S.Wrapper hide={hidden} numHeaders={numHeaders}>
      <S.FacetsContainer className={className}>
        <S.Header>
          <S.Clear withoutPadding={true} type="skip" hide={hidden} onClick={() => clearFilters()}>
            {__('Facets.clear')}
          </S.Clear>
          <S.Title>{__('Facets.filter_by')}</S.Title>
          <S.CloseIcon
            name="Close"
            onClick={() => {
              onHide?.(true);
              setHidden(true);
            }}
            hide={hidden}
          />
        </S.Header>
        <S.Body>
          {showDeliveryRangeFilter ? (
            ((facetsGlobal.range && Object.keys(facetsGlobal.range).length) || viewMode === 'delivery') &&
            viewMode !== 'prepare-orders' ? (
              <FacetFilter
                title={__('Global.Commons.deliveryDate.one')}
                options={getDeliveryDateOptions()}
                isRadio={true}
                numVisibleOptions={10}
                startFolded={false}
                onChange={deliveryDate => {
                  const { beginDate: deliveryDateStart, endDate: deliveryDateEnd } = date.getDatesFromRange(
                    deliveryDate as IDateRange,
                  );
                  changeSearchState(
                    {
                      ...searchState,
                      beginDeliveryDate: deliveryDateStart,
                      endDeliveryDate: deliveryDateEnd,
                    },
                    null,
                    deliveryDate as IDateRange,
                  );
                }}
              >
                <FacetFilterOption // delivery
                  isRadio={true}
                  option={{
                    key: 'custom',
                    name: viewMode === 'delivery' ? __('Messages.DateFilters.jump') : __('Messages.DateFilters.custom'),
                    checked: dateRangeDeliveryDate === 'custom',
                    hideAmount: true,
                  }}
                  onChange={() => {
                    const beginDeliveryDate = searchState.beginDeliveryDate || new Date();
                    const endDeliveryDate = searchState.endDeliveryDate || new Date();

                    changeSearchState(
                      {
                        ...searchState,
                        beginDeliveryDate: date.setSpecificDateTime(beginDeliveryDate, 0, 0, 0, 0),
                        endDeliveryDate: date.setSpecificDateTime(endDeliveryDate, 23, 59, 59, 999),
                      },
                      null, // creation
                      'custom', // delivery
                    );
                  }}
                />
                {dateRangeDeliveryDate === 'custom' ? (
                  ['order', 'request', 'import-job'].includes(viewMode) ? (
                    <>
                      <S.Row>
                        {renderDateDeliveryFilter(searchState.beginDeliveryDate, 'begin', me.settings.dateFormat)}
                        <S.ArrowIcon name="Arrow" disableHover={true} />
                        {renderDateDeliveryFilter(searchState.endDeliveryDate, 'future', me.settings.dateFormat)}
                      </S.Row>
                      {searchState.beginDeliveryDate > searchState.endDeliveryDate && (
                        <div style={{ color: 'red' }}>{__('Facets.dateError')}</div>
                      )}
                    </>
                  ) : (
                    renderDateDeliveryFilter(searchState.beginDeliveryDate, 'both', me.settings.dateFormat)
                  )
                ) : null}
              </FacetFilter>
            ) : null
          ) : null}

          {((facetsGlobal.range && Object.keys(facetsGlobal.range).length) || viewMode === 'delivery') &&
          viewMode !== 'prepare-orders' ? (
            <FacetFilter
              title={__('Facets.range')}
              options={getDateOptions()}
              isRadio={true}
              numVisibleOptions={10}
              startFolded={false}
              onChange={creationDate => {
                const { beginDate: creationDateStart, endDate: creationDateEnd } = date.getDatesFromRange(
                  creationDate as IDateRange,
                );
                changeSearchState(
                  {
                    ...searchState,
                    beginDate: creationDateStart,
                    endDate: creationDateEnd,
                  },
                  creationDate as IDateRange,
                );
              }}
            >
              <FacetFilterOption
                isRadio={true}
                option={{
                  key: 'custom',
                  name: viewMode === 'delivery' ? __('Messages.DateFilters.jump') : __('Messages.DateFilters.custom'),
                  checked: dateRange === 'custom',
                  hideAmount: true,
                }}
                onChange={() => {
                  const beginDate = searchState.beginDate || new Date();
                  const endDate = searchState.endDate || new Date();

                  changeSearchState(
                    {
                      ...searchState,
                      beginDate: date.setSpecificDateTime(beginDate, 0, 0, 0, 0),
                      endDate: date.setSpecificDateTime(endDate, 23, 59, 59, 999),
                    },
                    'custom',
                    null
                  );
                }}
              />
              {dateRange === 'custom' ? (
                ['order', 'request', 'import-job'].includes(viewMode) ? (
                  <S.Row>
                    {renderDateFilter(searchState.beginDate, 'begin', me.settings.dateFormat)}
                    <S.ArrowIcon name="Arrow" disableHover={true} />
                    {renderDateFilter(searchState.endDate, 'end', me.settings.dateFormat)}
                  </S.Row>
                ) : (
                  renderDateFilter(searchState.beginDate, 'both', me.settings.dateFormat)
                )
              ) : null}
            </FacetFilter>
          ) : null}
          {viewMode !== 'delivery' && facetsGlobal.origin && Object.keys(facetsGlobal.origin).length > 0 ? (
            <FacetFilter
              title={__('Facets.order_origin')}
              options={getOrderOriginOptions(facets, facetsGlobal, searchState)}
              numVisibleOptions={4}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, origin: v as Array<string> })}
            />
          ) : null}
          {(searchState.catalogHash || type === 'sale') &&
          !filtersBlacklist.includes('buyers') &&
          facetsGlobal.buyer_id &&
          Object.keys(facets.buyer_id || facetsGlobal.buyer_id).length ? (
            <FacetFilter
              title={__('Facets.client')}
              options={getBuyerOptions(facets, facetsGlobal, searchState, contacts, clients, me)}
              numVisibleOptions={4}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, buyers: (v as Array<string>).map(Number) })}
            />
          ) : null}
          {searchState.catalogHash || type === 'purchase' || viewMode === 'import-job' ? (
            !filtersBlacklist.includes('sellers') &&
            facetsGlobal.seller_id &&
            Object.keys(facets.seller_id || facetsGlobal.seller_id).length ? (
              <FacetFilter
                title={type === 'purchase' ? __('Facets.provider') : __('Facets.colleague')}
                options={getSellerOptions(facets, facetsGlobal, searchState, contacts, me, type)}
                numVisibleOptions={4}
                startFolded={false}
                onChange={v => changeSearchState({ ...searchState, sellers: (v as Array<string>).map(Number) })}
              />
            ) : null
          ) : facetsGlobal.catalogs && Object.keys(facetsGlobal.catalogs).length && viewMode !== 'prepare-orders' ? (
            <FacetFilter
              title={__('Facets.catalog')}
              options={Object.values(catalogs)
                .filter(c => Object.keys(facetsGlobal.catalogs).includes(c.hashId))
                .map(catalog => {
                  if (!catalog) return null;
                  return {
                    name: sellerWorkspaceService.getCatalogName(catalog, contacts, me),
                    image: catalog.companyImage,
                    type: 'team',
                    key: catalog.hashId,
                    count: facets.catalogs?.[catalog.hashId] || facetsGlobal.catalogs[catalog.hashId],
                    checked: searchState.catalogHashs?.includes(catalog.hashId),
                  };
                })}
              numVisibleOptions={4}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, catalogHashs: v as Array<ORDER_STATUS> })}
            />
          ) : null}
          {!filtersBlacklist.includes('sellers') &&
          type === 'sale' &&
          !searchState.catalogHash &&
          facets.sellers &&
          Object.keys(facets.sellers).length ? (
            <FacetFilter
              title={__('Facets.seller')}
              options={getSellerOptions(facets, facetsGlobal, searchState, contacts, me, type)}
              numVisibleOptions={3}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, sellers: (v as Array<string>).map(Number) })}
            />
          ) : null}

          {facets.formats && Object.keys(facets.formats).length ? (
            <FacetFilter
              title={__('Facets.format_type')}
              options={getParseFormatOption(viewMode, facets, facetsGlobal, searchState, formats.all)}
              numVisibleOptions={5}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, formats: v as Array<string> })}
            />
          ) : null}

          {!filtersBlacklist.includes('sellers') &&
          type === 'purchase' &&
          !searchState.catalogHash &&
          facetsGlobal.buyer_id &&
          Object.keys(facetsGlobal.buyer_id).length ? (
            <FacetFilter
              title={__('Facets.colleague')}
              options={getBuyerOptions(facets, facetsGlobal, searchState, contacts, clients, me)}
              numVisibleOptions={3}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, buyers: (v as Array<string>).map(Number) })}
            />
          ) : null}
          {facetsGlobal.sections && Object.keys(facetsGlobal.sections).length ? (
            <FacetFilter
              title={__('Facets.sections')}
              options={Object.keys(facets.sections || facetsGlobal.sections).map(section => ({
                name: section === NO_TAG ? __('Showroom.no_tag') : section,
                key: section,
                count: facets.sections?.[section] || facetsGlobal.sections[section],
                checked: searchState.sections?.includes(section),
              }))}
              numVisibleOptions={4}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, sections: v as Array<string> })}
            />
          ) : null}
          {filterByDeliveryAt ? renderByDeliveryAt() : renderOrders()}
          {viewMode === 'prepare-orders' && facetsGlobal.prepared && Object.keys(facetsGlobal.prepared).length ? (
            <FacetFilter
              title={__('Facets.preparation_state')}
              options={Object.keys(facets.prepared || facetsGlobal.prepared).map(code => {
                return {
                  key: code,
                  name: code === 'false' ? __('Facets.to_be_prepared') : __('Facets.prepared'),
                  count: facets.prepared?.[code] || facetsGlobal.prepared[code],
                  checked: searchState.prepared?.includes(code as 'true' | 'false'),
                };
              })}
              numVisibleOptions={3}
              startFolded={false}
              onChange={c =>
                changeSearchState({ ...searchState, prepared: c.length ? (c as Array<'true' | 'false'>) : undefined })
              }
            />
          ) : null}
        </S.Body>
      </S.FacetsContainer>
    </S.Wrapper>
  );

  function renderOrders() {
    return (
      <>
        {facetsGlobal.status && Object.keys(facetsGlobal.status).length && viewMode !== 'prepare-orders' ? (
          <FacetFilter
            title={__('Facets.status')}
            options={Object.keys(facets.status || facetsGlobal.status).map(status => ({
              name: orderService.getOrderStatusLabel(status as ORDER_STATUS),
              key: status,
              count: facets.status?.[status] || facetsGlobal.status[status],
              checked: searchState.status?.includes(status as ORDER_STATUS),
            }))}
            numVisibleOptions={4}
            startFolded={false}
            onChange={v => changeSearchState({ ...searchState, status: v as Array<ORDER_STATUS> })}
          />
        ) : null}
        {facetsGlobal.currency && Object.keys(facetsGlobal.currency).length && viewMode !== 'prepare-orders' ? (
          <FacetFilter
            title={__('Facets.currency')}
            isRadio={true}
            options={Object.keys(facets.currency || facetsGlobal.currency).map(code => {
              const currency = Object.values(constants.CURRENCIES).find(c => c.code === code);
              return {
                key: currency.code,
                name: currency.code + ' - ' + currency.name(),
                count: facets.currency?.[currency.code] || facetsGlobal.currency[currency.code],
                checked: currency.code === searchState.currency,
              };
            })}
            numVisibleOptions={3}
            startFolded={false}
            onChange={c => changeSearchState({ ...searchState, currency: c as CURRENCY_CODES })}
          />
        ) : null}
      </>
    );
  }
  function renderByDeliveryAt() {
    return (
      <>
        {facetsGlobal.shippingAddress && Object.keys(facetsGlobal.shippingAddress).length ? (
          <FacetFilter
            title={__('Facets.shippingAddress')}
            options={Object.keys(facets.shippingAddress || facetsGlobal.shippingAddress).map(t => ({
              name: t || __('Facets.unspecified'),
              key: t,
              count: facets.shippingAddress?.[t] || facetsGlobal.shippingAddress[t],
              checked: searchState.shippingAddress?.includes(t),
            }))}
            numVisibleOptions={4}
            startFolded={false}
            onChange={v => changeSearchState({ ...searchState, shippingAddress: v as Array<string> })}
          />
        ) : null}
        {facetsGlobal.type && Object.keys(facetsGlobal.type).length ? (
          <FacetFilter
            title={__('Facets.type')}
            options={Object.keys(facets.type || facetsGlobal.type).map(t => ({
              name: prodTypes?.[t]?.name || __('Facets.unspecified'),
              key: t,
              count: facets.type?.[t] || facetsGlobal.type[t],
              checked: searchState.type?.includes(t),
            }))}
            numVisibleOptions={4}
            startFolded={false}
            onChange={v => changeSearchState({ ...searchState, type: v as Array<string> })}
          />
        ) : null}
        {facetsGlobal.origin && Object.keys(facetsGlobal.origin).length ? (
          <FacetFilter
            title={__('Facets.origin')}
            options={Object.keys(facets.origin || facetsGlobal.origin).map(origin => ({
              name: Object.values(countries).find(c => c.iso2Code === origin)?.name || __('Facets.unspecified'),
              key: origin,
              count: facets.origin?.[origin] || facetsGlobal.origin[origin],
              checked: searchState.origin?.includes(origin),
            }))}
            numVisibleOptions={4}
            startFolded={false}
            onChange={v => changeSearchState({ ...searchState, origin: v as Array<string> })}
          />
        ) : null}
        {facetsGlobal.category && Object.keys(facetsGlobal.category).length ? (
          <FacetFilter
            title={__('Facets.category')}
            options={Object.keys(facets.category || facetsGlobal.category).map(
              (category: (typeof constants.PRODUCT_CATEGORIES)[number]) => ({
                name: getCategoryText(category) || __('Facets.unspecified'),
                key: category,
                count: facets.category?.[category] || facetsGlobal.category[category],
                checked: searchState.category?.includes(category),
              }),
            )}
            numVisibleOptions={4}
            startFolded={false}
            onChange={v => changeSearchState({ ...searchState, category: v as Array<string> })}
          />
        ) : null}
        {facetsGlobal.currency && Object.keys(facetsGlobal.currency).length ? (
          <FacetFilter
            title={__('Facets.currency')}
            isRadio={true}
            options={Object.keys(facets.currency || facetsGlobal.currency).map(code => {
              const currency = Object.values(constants.CURRENCIES).find(c => c.code === code);
              return {
                key: currency.code,
                name: currency.code + ' - ' + currency.name(),
                count: facets.currency?.[currency.code] || facetsGlobal.currency[currency.code],
                checked: currency.code === searchState.currency,
              };
            })}
            numVisibleOptions={3}
            startFolded={false}
            onChange={c => changeSearchState({ ...searchState, currency: c as CURRENCY_CODES })}
          />
        ) : null}
      </>
    );
  }

  /**
   * Get date filters according to the view mode
   */
  function getDeliveryDateOptions(): Array<IFilterOption> {
    return [
      {
        key: 'all',
        name: __('Messages.DateFilters.all'),
        checked: dateRangeDeliveryDate === 'all',
        count: facetsGlobal.delivery_range?.[6],
      },
      {
        key: 'tomorrow',
        name: __('Messages.DateFilters.tomorrow'),
        checked: dateRangeDeliveryDate === 'tomorrow',
        count: facetsGlobal.delivery_range?.[0],
      },
      {
        key: 'today',
        name: __('Messages.DateFilters.today'),
        checked: dateRangeDeliveryDate === 'today',
        count: facetsGlobal.delivery_range?.[1],
      },
      {
        key: 'yesterday',
        name: __('Messages.DateFilters.yesterday'),
        checked: dateRangeDeliveryDate === 'yesterday',
        count: facetsGlobal.delivery_range?.[2],
      },
      {
        key: 'week',
        name: __('Messages.DateFilters.week'),
        count: facetsGlobal.delivery_range?.[3],
        checked: dateRangeDeliveryDate === 'week',
      },
      {
        key: 'month',
        name: __('Messages.DateFilters.month'),
        count: facetsGlobal.delivery_range?.[4],
        checked: dateRangeDeliveryDate === 'month',
      },
      {
        key: 'year',
        name: __('Messages.DateFilters.year'),
        count: facetsGlobal.delivery_range?.[5],
        checked: dateRangeDeliveryDate === 'year',
      },
      // TODO: update this
      // {
      //   key: 'unknown',
      //   name: __('Facets.unknown'),
      //   checked: dateRangeDeliveryDate === 'unknown',
      //   count: 0,
      //   hideAmount: true,
      // },
    ];
  }

  function getDateOptions(): Array<IFilterOption> {
    if (viewMode === 'delivery') {
      return [
        { key: 'all', name: __('Messages.DateFilters.all'), checked: dateRange === 'all', count: 0, hideAmount: true },
        {
          key: 'tomorrow',
          name: __('Messages.DateFilters.tomorrow'),
          checked: dateRange === 'tomorrow',
          hideAmount: true,
        },
        {
          key: 'today',
          name: __('Messages.DateFilters.today'),
          checked: dateRange === 'today',
          count: 0,
          hideAmount: true,
        },
        {
          key: 'yesterday',
          name: __('Messages.DateFilters.yesterday'),
          checked: dateRange === 'yesterday',
          count: 0,
          hideAmount: true,
        },
        { key: 'unknown', name: __('Facets.unknown'), checked: dateRange === 'unknown', count: 0, hideAmount: true },
      ];
    }
    return [
      ['request', 'import-job'].includes(viewMode) || showAll
        ? {
            key: 'all',
            name: __('Messages.DateFilters.all'),
            checked: dateRange === 'all',
            count: (facets.range || facetsGlobal.range)?.[5],
          }
        : null,
      {
        key: 'today',
        name: __('Messages.DateFilters.today'),
        count: (facets.range || facetsGlobal.range)?.[0],
        checked: dateRange === 'today',
      },
      {
        key: 'yesterday',
        name: __('Messages.DateFilters.yesterday'),
        count: (facets.range || facetsGlobal.range)?.[1],
        checked: dateRange === 'yesterday',
      },
      {
        key: 'week',
        name: __('Messages.DateFilters.week'),
        count: (facets.range || facetsGlobal.range)?.[2],
        checked: dateRange === 'week',
      },
      {
        key: 'month',
        name: __('Messages.DateFilters.month'),
        count: (facets.range || facetsGlobal.range)?.[3],
        checked: dateRange === 'month',
      },
      {
        key: 'year',
        name: __('Messages.DateFilters.year'),
        count: (facets.range || facetsGlobal.range)?.[4],
        checked: dateRange === 'year',
      },
    ];
  }

  /**
   * Render a custom date filter
   */
  function renderDateFilter(dateToShow: Date, kind: 'begin' | 'end' | 'both' | 'future', dateFormat: DATE_FORMAT) {
    return (
      <S.DatePicker
        clearable={false}
        dateFormat={dateFormat === 'dd/mm/yyyy' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
        id={`date-picker-${kind}`}
        initDate={dateToShow || new Date()}
        minDate={(kind === 'end' && searchState.beginDate) || undefined}
        maxDate={
          (kind === 'begin' && searchState.endDate) || ['order', 'request'].includes(viewMode) ? new Date() : undefined
        }
        openDate={dateToShow || new Date()}
        onDateChange={newDate => {
          if (kind === 'begin') {
            changeSearchState(
              { ...searchState, beginDate: date.setSpecificDateTime(new Date(newDate), 0, 0, 0) },
              'custom',
            );
          } else if (kind === 'end') {
            changeSearchState(
              {
                ...searchState,
                endDate: date.setSpecificDateTime(new Date(newDate), 23, 59, 59),
              },
              'custom',
            );
          } else {
            changeSearchState(
              {
                ...searchState,
                beginDate: date.setSpecificDateTime(new Date(newDate), 0, 0, 0),
                endDate: date.setSpecificDateTime(new Date(newDate), 23, 59, 59),
              },
              'custom',
            );
          }
        }}
      />
    );
  }

  function renderDateDeliveryFilter(
    dateToShow: Date,
    kind: 'begin' | 'end' | 'both' | 'future',
    dateFormat: DATE_FORMAT,
  ) {
    return (
      <S.DatePicker
        clearable={false}
        dateFormat={dateFormat === 'dd/mm/yyyy' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
        id={`date-picker-delivery-${kind}`}
        initDate={dateToShow || new Date()}
        minDate={(kind === 'end' && searchState.beginDeliveryDate) || undefined}
        maxDate={undefined}
        openDate={dateToShow || new Date()}
        onDateChange={newDate => {
          if (kind === 'begin') {
            changeSearchState(
              { ...searchState, beginDeliveryDate: date.setSpecificDateTime(new Date(newDate), 0, 0, 0) },
              null,
              'custom',
            );
          } else if (kind === 'end') {
            changeSearchState(
              {
                ...searchState,
                endDeliveryDate: date.setSpecificDateTime(new Date(newDate), 23, 59, 59),
              },
              null,
              'custom',
            );
          } else if (kind === 'future') {
            changeSearchState(
              {
                ...searchState,
                endDeliveryDate: date.setSpecificDateTime(new Date(newDate), 23, 59, 59),
              },
              null,
              'custom',
            );
          } else {
            changeSearchState(
              {
                ...searchState,
                beginDeliveryDate: date.setSpecificDateTime(new Date(newDate), 0, 0, 0),
                endDeliveryDate: date.setSpecificDateTime(new Date(newDate), 23, 59, 59),
              },
              null,
              'custom',
            );
          }
        }}
      />
    );
  }
};

/**
 * Get sellers from facets
 */
function getSellerOptions(
  facets: IFacets,
  facetsGlobal: IFacets,
  searchState: ISearchOrder,
  contacts: { [cId: number]: IContact },
  me: IUser,
  type?: IOrderType,
) {
  return Object.keys(facets.seller_id || facetsGlobal.seller_id || {}).reduce((acc, sellerId) => {
    const contact = Number(sellerId) === me.id ? getMeAsContact(me) : contacts[Number(sellerId)];
    if (contact)
      acc.push({
        name: contact.id === me.id ? `${contact.name} ${__('Facets.you')}` : contact.name,
        key: contact.id + '',
        count: facets.seller_id?.[contact.id] || facetsGlobal.seller_id?.[contact.id],
        checked: searchState.sellers?.includes(contact.id),
        image: contact.avatar,
        type: 'contact',
        workingStatus: type === 'sale' ? contact.workingStatus : undefined,
      });
    return acc;
  }, [] as any);
}

/**
 * Get buyers from facets
 */
function getBuyerOptions(
  facets: IFacets,
  facetsGlobal: IFacets,
  searchState: ISearchOrder,
  contacts: { [cId: number]: IContact },
  clients: Array<IClient>,
  me: IUser,
) {
  return Object.keys(facets.buyer_id || facetsGlobal.buyer_id || {}).reduce((acc, buyerId) => {
    const contact = Number(buyerId) === me.id ? getMeAsContact(me) : contacts[Number(buyerId)];
    if (contact) {
      acc.push({
        name: Number(buyerId) === me.id ? `${contact.name} ${__('Facets.you')}` : contact.name,
        key: buyerId + '',
        count: facets.buyer_id?.[buyerId] || facetsGlobal.buyer_id?.[buyerId],
        checked: searchState.buyers?.includes(Number(buyerId)),
        image: (contact as any).companyLogo || contact.avatar,
        type: 'contact',
      });
    } else if (clients?.length) {
      const client = clients.find(c => c.userId === Number(buyerId));
      if (client) {
        acc.push({
          name: Number(buyerId) === me.id ? `${client.name} ${__('Facets.you')}` : client.name,
          key: buyerId + '',
          count: facets.buyer_id?.[buyerId] || facetsGlobal.buyer_id?.[buyerId],
          checked: searchState.buyers?.includes(Number(buyerId)),
          image: (client as any).companyLogo || client.avatar,
          type: 'contact',
        });
      }
    }
    return acc;
  }, [] as any);
}

/**
 * Get order source origin from facets
 */
function getOrderOriginOptions(facets: IFacets, facetsGlobal: IFacets, searchState: ISearchOrder) {
  return Object.keys(facets.origin || facetsGlobal.origin || {}).map(o => {
    return {
      name: getOrderSourceText(o),
      key: o + '',
      count: facets.origin?.[o] || facetsGlobal.origin[o],
      checked: searchState.origin?.includes(o),
    };
  });
}

/**
 * Get format option for facets
 */
function getParseFormatOption(
  view: string,
  formatFacets: IFacets,
  facetsGlobal: IFacets,
  searchState: ISearchOrder,
  formats: Array<IFormat>,
) {
  let amountType: IAmountType = 'order';
  if (view === 'import-job') {
    amountType = 'file';
  }
  return Object.keys(facetsGlobal.formats || {}).reduce((collection, id) => {
    const format = formats.find(f => f.id === id);
    if (!format) return collection;
    collection.push({
      name: format.label,
      key: id,
      count: formatFacets?.formats[id] || 0,
      checked: searchState.formats?.includes(id),
      image: format.logo,
      type: 'reference',
      amountType,
    });
    return collection;
  }, [] as Array<IFilterOption>);
}

/**
 * Get an equivalent contact object of me
 */
export function getMeAsContact(me: IUser): ILightContact {
  return {
    avatar: me.settings.avatar,
    avatarColor: utils.getAvatarColor(me.name),
    name: me.name,
    phoneNumbers: [me.phone + ''],
    companyName: me.companyName,
    id: me.id,
    isUnregistered: false,
    workingStatus: me.settings.workingStatus,
  };
}

export default React.memo(Facets);