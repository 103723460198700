import {
  Api,
  buyerWorkspaceActions,
  contactActions,
  modalActions,
  notificationsActions,
  orderActions,
  sellerWorkspaceActions,
  userActions,
} from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  IDispatchProps,
  IOwnProps,
  IRouteProps,
  IStateProps,
} from '../components/organisms/OrdersList/OrdersList.constants';
import { OrdersList } from '../components/organisms/OrdersList/OrdersList.component';
import { IReduxState } from '../reducers';

const emptyObject = {};

function mapStateToProps(state: IReduxState, props: IOwnProps & IRouteProps): IStateProps {
  const workspaceId = Number(props.match.params.workspaceId || 0);
  const {
    catalog: { catalogs, clients },
    order: { dashboards, facets, facetsGlobal, hasMore, orders, searchId, totalResults },
    user: { user },
    workspace: { suppliers, workspaces },
  } = state;

  const orderFiltersState = state.order.filters || emptyObject;
  const orderSelected = props.hashId
    ? orders.find(o => (props.hashId === 'new' ? !o.hashId : o.hashId === props.hashId))
    : undefined;
  return {
    amSeller: state.user.user.settings.isSeller,
    catalogs: catalogs || emptyObject,
    clients,
    contacts: state.contact.inConsentio,
    dashboard: dashboards?.[workspaceId],
    facets,
    facetsGlobal,
    filters: orderFiltersState[0] || emptyObject,
    hasMore,
    me: user!,
    orders,
    orderSelected,
    searchId,
    suppliers,
    totalResults,
    workspaces,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      clientsGet: sellerWorkspaceActions.clientsGet,
      orderFiltersSet: orderActions.orderFiltersSet,
      orderGetByHash: orderActions.orderGetByHash,
      orderScrollSearch: orderActions.orderScrollSearch,
      ordersDashboardGet: orderActions.ordersDashboardGet,
      ordersDashboardWorkspaceGet: orderActions.ordersDashboardWorkspaceGet,
      orderSearch: orderActions.orderSearch,
      ordersAllMarkAsRead: orderActions.ordersAllMarkAsRead,
      orderToggleReadStatus: orderActions.toggleOrderReadStatus,
      touchImage: modalActions.touchImage,
      modalOpen: modalActions.modalOpen,
      modalClose: modalActions.modalClose,
      notificationShow: notificationsActions.notificationShow,
      requestProInfo: userActions.requestProInfo,
      suppliersGet: buyerWorkspaceActions.suppliersGet,
      createNewContact: contactActions.createContactWithName,
      contactUpdateMySellerWorkspace: contactActions.contactUpdateMySellerWorkspace,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersList);
