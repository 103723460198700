import {
  __,
  CURRENCY_CODES,
  modalActions,
  notificationsActions,
  orderActions,
  qs,
  RenderTrack,
  sellerWorkspaceService,
  userActions,
} from 'common-services';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ROUTE_PATHS } from '../../../constants';
import Layout from '../../../layout-flex';
import OrdersList from '../../../screens/orders-list';
import getPath from '../../../util/routes';
import * as S from './OrdersDashboard.styled';

export type IRouteProps = RouteComponentProps<{ hashId?: string; catalogHash?: string }>;

export interface IStateProps {
  amSeller: boolean;
  catalogs: { [id: number]: IWorkspace };
  contacts: { [cId: number]: IContact };
  me: IUser;
  filters: IOrderFilters;
  onlyUnreads?: boolean;
  orders: Array<IOrder>;
  orderUnreads?: { total: number; sales: number; purchases: number };
  searchId: string;
  suppliers: { [id: number]: Array<ISupplier> };
  type?: IOrderType;
  workspaces: { [id: number]: IWorkspace };
  totalResults: number;
}

export interface IDispatchProps {
  orderFiltersSet: typeof orderActions.orderFiltersSet;
  orderGetByHash: typeof orderActions.orderGetByHash;
  orderScrollSearch: typeof orderActions.orderScrollSearch;
  ordersDashboardGet: typeof orderActions.ordersDashboardGet;
  orderSearch: typeof orderActions.orderSearch;
  ordersAllMarkAsRead: typeof orderActions.ordersAllMarkAsRead;
  orderToggleReadStatus: typeof orderActions.toggleOrderReadStatus;
  touchImage: typeof modalActions.touchImage;
  modalOpen: typeof modalActions.modalOpen;
  modalClose: typeof modalActions.modalClose;
  notificationShow: typeof notificationsActions.notificationShow;
  requestProInfo: typeof userActions.requestProInfo;
}

type IProps = IStateProps & IDispatchProps & IRouteProps;

interface IState {
  isUnselect: boolean;
  orderItems: Array<IOrderAggregationItem>;
  orderRequests: Array<IOrderRequest>;
  orderRequestSelected?: IOrderRequest;
  orderSelectedIds: Array<number>;
  showContactsModal?: boolean;
  contactSelected?: IContact;
  tabSelected?: string;
  viewMode: 'order' | 'delivery' | 'request';
}

/**
 * Order list dashboard with widgets
 */
class OrdersDashboard extends React.PureComponent<IProps, IState> {
  private t: number;

  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    const {
      type,
      match: { params },
      onlyUnreads,
    } = props;
    const { isUnselect, orderType, selected, viewMode } = this.getInitialFilters();
    this.state = {
      viewMode,
      orderItems: [],
      orderRequests: [],
      orderSelectedIds: selected ? selected.split(',').map(id => Number(id)) : [],
      isUnselect,
      tabSelected: params?.catalogHash || (onlyUnreads && 'unread') || type || orderType,
    };
  }

  public componentDidMount() {
    const { type } = this.props;
    const { tabSelected } = this.state;
    RenderTrack.track('OrderList', { renderTime: this.t, type, tab: tabSelected });
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { catalogs, match, onlyUnreads, type } = this.props;
    if (prevProps.type !== type || prevProps.onlyUnreads !== onlyUnreads) {
      this.setState({ tabSelected: onlyUnreads ? 'unread' : type });
    }

    if (prevProps.match.params.catalogHash !== match.params.catalogHash || prevProps.catalogs !== catalogs) {
      this.setState({
        tabSelected: match.params.catalogHash || type,
      });
    }
  }
  public render() {
    const { history, match, location, type, onlyUnreads } = this.props;
    const { tabSelected, viewMode } = this.state;
    return (
      <Layout
        header={{
          show: true,
          title:
            type === 'sale' ? __('Components.OrdersList.title_sales') : __('Components.OrdersList.title_purchases'),
          breadcrumbs: [
            {
              label:
                type === 'sale' ? __('Components.OrdersList.title_sales') : __('Components.OrdersList.title_purchases'),
              action: () =>
                history.push(getPath({ path: type === 'sale' ? ROUTE_PATHS.SALES : ROUTE_PATHS.PURCHASES })),
            },
            {
              label: this.getNavTabs().find(t => t.id === tabSelected)?.label || '',
            },
            ...(match.params.hashId
              ? [
                  {
                    label: match.params.hashId,
                  },
                ]
              : []),

            ...(type === 'sale' && viewMode === 'request' ? [{ label: __('Components.OrdersList.requests') }] : []),
          ],
          tabSelected: type,
        }}
      >
        <S.OrdersContainer>
          <S.NavigationTabs selected={tabSelected} tabs={this.getNavTabs()} history={history} handleOverflowX={true} />
          <OrdersList
            catalogHash={match.params?.catalogHash}
            hashId={match.params?.hashId}
            history={history}
            location={location}
            match={match}
            numberOfHeaders={2}
            onlyUnreads={onlyUnreads}
            setViewMode={vm => this.setState({ viewMode: vm })}
            tabSelected={tabSelected}
            type={type}
            updateUrl={this.updateUrl}
            viewMode={viewMode}
          />
        </S.OrdersContainer>
      </Layout>
    );
  }

  private getNavTabs = () => {
    const { catalogs, contacts, history, location, me, orderUnreads, type, workspaces } = this.props;
    return [
      ...(type === 'sale'
        ? [
            {
              id: 'sale',
              label: __('Components.OrdersList.my_sales'),
              url:
                getPath({
                  path: ROUTE_PATHS.SALES,
                }) + location.search,
            },
          ]
        : []),
      ...(type === 'purchase'
        ? [
            {
              id: 'purchase',
              label: __('Components.OrdersList.my_purchases'),
              url:
                getPath({
                  path: ROUTE_PATHS.PURCHASES,
                }) + location.search,
            },
          ]
        : []),
      {
        id: 'unread',
        label: __('Components.OrdersList.title_unreads'),
        badge: orderUnreads[type === 'sale' ? 'sales' : 'purchases'],
        action: () =>
          history.push(
            getPath({ path: type === 'sale' ? ROUTE_PATHS.SALES_UNREAD : ROUTE_PATHS.PURCHASES_UNREAD }) +
              location.search,
          ),
      },
      ...(type === 'sale'
        ? Object.values(catalogs).map(c => {
            return {
              id: c.hashId,
              image: c.companyImage || c.companyLogo,
              label: sellerWorkspaceService.getCatalogName(c, contacts, me),
              url:
                getPath({
                  path: ROUTE_PATHS.SALES_WORKSPACE,
                  catalogHash: c.hashId,
                }) + location.search,
              type: 'team',
            };
          })
        : []),
      ...(type === 'purchase'
        ? Object.values(workspaces).map(w => {
            return {
              id: w.hashId,
              image: w.companyImage || w.companyLogo,
              label: sellerWorkspaceService.getCatalogName(w, contacts, me),
              url:
                getPath({
                  path: ROUTE_PATHS.PURCHASES_WORKSPACE,
                  catalogHash: w.hashId,
                }) + location.search,
              type: 'team',
            };
          })
        : []),
    ];
  };

  /**
   * Update the current url in the browser if navigate to/from a order.
   */
  private updateUrl = (
    searchState: ISearchOrder,
    dateRange: IDateRange,
    order?: IOrder | { hashId?: string; buyerId?: number; catalogId?: number; buyerWorkspaceId: number },
  ) => {
    const { history, match, me, type, totalResults } = this.props;
    const { orderSelectedIds, isUnselect, viewMode } = this.state;
    const isAll = totalResults > 0 && orderSelectedIds.length === totalResults;

    if (type && (!order || order.catalogId)) {
      const path =
        getPath(
          type === 'purchase'
            ? {
                path: ROUTE_PATHS.WORKSPACE_PURCHASES,
                workspaceId: (order?.buyerWorkspaceId || me.buyerWorkspaceId) + '',
                hashId: order ? order.hashId || 'new' : '',
              }
            : {
                path: ROUTE_PATHS.WORKSPACE_SALES,
                workspaceId: (order?.catalogId || me.sellerWorkspaceId) + '',
                hashId: order ? order.hashId || 'new' : '',
              },
        ).replace('?', '') +
        qs.stringify({
          currency: searchState.currency,
          range: dateRange,
          selected: isAll ? 'all' : orderSelectedIds.join(','),
          since: searchState.beginDate && searchState.beginDate.getTime(),
          until: searchState.endDate && searchState.endDate.getTime(),
          is_unselect: isUnselect,
          view_mode: viewMode,
        });
      return history.replace(path);
    }
    if (type || order.buyerId) {
      let path = order?.buyerId === me.id || type === 'purchase' ? ROUTE_PATHS.PURCHASES : ROUTE_PATHS.SALES;
      if (match.params.catalogHash)
        path = type === 'purchase' ? ROUTE_PATHS.PURCHASES_WORKSPACE : ROUTE_PATHS.SALES_WORKSPACE;

      history.replace(
        getPath({
          path,
          hashId: order ? order.hashId || 'new' : '',
          catalogHash: match.params.catalogHash,
        }).replace('?', '') +
          qs.stringify({
            currency: searchState.currency,
            range: dateRange,
            selected: isAll ? 'all' : orderSelectedIds.join(','),
            since: searchState.beginDate && searchState.beginDate.getTime(),
            until: searchState.endDate && searchState.endDate.getTime(),
            is_unselect: isUnselect,
            view_mode: viewMode,
          }),
      );
    }
  };

  /**
   * Get initial filters for this contact
   */
  private getInitialFilters = () => {
    // Load selected orders from url params
    const { amSeller, catalogs, location, me, filters, type } = this.props;
    const { currency, is_unselect, selected, view_mode } = qs.parse(location.search || '', [
      'currency',
      'is_unselect',
      'range',
      'selected',
      'since',
      'type',
      'until',
      'view_mode',
    ]) as {
      currency?: CURRENCY_CODES;
      range?: IDateRange;
      selected?: string;
      is_unselect?: string;
      since?: string;
      until?: string;
      only_unreads?: string;
      view_mode?: 'order' | 'request' | 'delivery';
    };
    const defaultType = filters.type || (amSeller ? 'sale' : 'purchase');
    const orderType = (type || defaultType) as IOrderType;
    const defaultCurrency = currency || filters.currency || catalogs[me.sellerWorkspaceId!]?.defaultCurrency;
    let viewMode = view_mode || 'order';
    if (orderType === 'sale' && !['order', 'request'].includes(viewMode)) viewMode = 'order';
    if (orderType === 'purchase' && !['order', 'delivery'].includes(viewMode)) viewMode = 'order';

    return {
      defaultCurrency,
      isUnselect: is_unselect === 'true',
      orderType,
      selected,
      viewMode,
    };
  };
}

export default OrdersDashboard;
