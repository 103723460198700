import {
  __,
  chatSelectors,
  currency,
  i18n,
  IOfferedProduct,
  IReferenceResponse,
  IReferenceSellerView,
  modalActions,
  parsers,
  prodTypeSelectors,
  productService,
  userSelectors,
} from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import config from '../../../../bindings/config';
import * as navActions from '../../../actions/nav';
import { IMAGES } from '../../../assets';
import { ROUTE_PATHS } from '../../../constants';
import getPath from '../../../util/routes';
import { unitTranslator } from '../../../util/unit';
import { FontIcon, Input, Select } from '../../atoms';
import ActionsModal from '../ActionsModal';
import AddressModal from '../AddressModal';
import EmptyListResource from '../EmptyListResource';
import Table from '../Table';
import { IColumn } from '../Table/Table.component';
import * as S from './OfferedProductTable.styled';

export interface IProps {
  addAddress: (address: IAddress) => void;
  addresses?: Array<IAddress>;
  clientAssociations?: Array<IReferenceSellerView>;
  client: IClient;
  deleteProduct: (id: string) => void;
  editable?: boolean;
  offeredProducts: Array<IOfferedProduct>;
  products: Array<GenericProduct & { cellClassName?: string }>;
  removeAddress: (id: number) => void;
  searchProducts: () => void;
  setHasChanges: () => void;
  setHasReferenceConflicts: (isPublishable: boolean) => void;
  setHasMissingAssociations: (isSaveable: boolean) => void;
  setProductOffer: (sku: string, name: string, value: number | string | boolean, warehouseId?: number) => void;
  references?: Array<IReferenceResponse>;
  loadingReferences?: boolean;
  totalAddresses?: Array<IAddress>;
  workspaceId: string;
  removeAssociation: (referenceId: number, sku: string) => void;
  associateProduct: (referenceId: number, sku: string) => void;
}

const OfferedProductTable: React.FC<IProps> = ({
  addAddress,
  addresses,
  clientAssociations,
  client,
  deleteProduct,
  editable,
  offeredProducts,
  products,
  removeAddress,
  references,
  loadingReferences,
  searchProducts,
  setHasChanges,
  setHasReferenceConflicts,
  setHasMissingAssociations,
  setProductOffer,
  totalAddresses,
  workspaceId,
  removeAssociation,
  associateProduct,
}) => {
  const [showSearchAddress, setShowSearchAddress] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState<GenericProduct | null>(null);
  const [associatedReferenceId, setAssociatedReferenceId] = React.useState<number | null>(null);
  React.useEffect(() => {
    const newConflicts = products.map(p => {
      const { hasRepeatedSkus } = checkRepeatedClientAssociations(p, clientAssociations, products);
      return { sku: p.sku, error: hasRepeatedSkus };
    });

    if (newConflicts.some(c => c.error)) {
      setHasReferenceConflicts(true);
    } else {
      setHasReferenceConflicts(false);
    }
  }, [products, clientAssociations]);

  const [productReferences, setProductReferences] = React.useState<Array<{ sku: string; hasReference: boolean }>>([]);

  // This useEffect is used to update the product associations state when the products array changes.
  React.useEffect(() => {
    // Create lookup maps for faster access
    const productReferenceMap = productReferences.reduce((acc, pr) => {
      acc[pr.sku] = pr;
      return acc;
    }, {});

    const clientAssociationMap = clientAssociations.reduce((acc, ca) => {
      acc[ca.sku] = ca;
      return acc;
    }, {});

    const newProductReferences = products.map(p => {
      const productWithReference = productReferenceMap[p.sku];
      const association = clientAssociationMap[p.sku];

      return {
        sku: p.sku,
        hasReference: productWithReference?.hasReference || !!association,
      };
    });

    setProductReferences(newProductReferences);
  }, [products]);

  React.useEffect(() => {
    setProductReferences(prevReferences => {
      // Convert clientAssociations to a map for quicker lookups
      const associationMap = new Map(clientAssociations.map(ca => [ca.sku, ca]));

      // Update references based on clientAssociations
      return prevReferences.map(pr => ({
        ...pr,
        hasReference: !!associationMap.get(pr.sku),
      }));
    });
  }, [clientAssociations]);

  React.useEffect(() => {
    if (productReferences.some(c => c.hasReference === false)) {
      setHasMissingAssociations(true);
    } else {
      setHasMissingAssociations(false);
    }
  }, [productReferences]);

  const channels: Record<number, IChannel> = useSelector(chatSelectors.getChannels());
  const history = useHistory();
  const prodTypes = useSelector(prodTypeSelectors.getProdTypes);

  const dispatch = useDispatch<any>();

  const toggleAssociateProductsEnabled = useSelector(userSelectors.hasToggleEnabled(config.TOGGLE_SELLER_OFFER_TABLE));

  const checkRepeatedClientAssociations = (data, clientAssociations, products) => {
    let hasRepeatedReferences = false;
    let filteredAssociations = [];
    let referenceSellerView;
    let hasMultipleReferences;
    if (clientAssociations?.length) {
      referenceSellerView = clientAssociations.find(ca => ca.sku === data.sku);
      hasRepeatedReferences =
        clientAssociations.filter(as => as?.reference.id === referenceSellerView?.reference.id).length > 1;
      const associationsWithReferencesInTheOffer = clientAssociations.filter(
        as => as?.reference.id === referenceSellerView?.reference.id,
      );
      const productSkus = products.map(p => p.sku);
      const associationsWithRepeatedSkus = clientAssociations.filter((ca, index, self) => {
        return self.filter(ca2 => ca2?.reference.id === ca?.reference.id).length > 1;
      });
      const selectedSkus = products.map(p => +p.sku);
      filteredAssociations = associationsWithRepeatedSkus.filter(as => selectedSkus.includes(+as.sku));
      // check if associationsWithReferencesInTheOffer has an element with sku in the array of productSKus besides the one that is being checked
      hasMultipleReferences = associationsWithReferencesInTheOffer.some(
        el => productSkus.includes(el.sku) && el.sku !== data.sku,
      );
    }

    const hasRepeatedSkus = hasMultipleReferences && filteredAssociations.length > 1;

    return { hasRepeatedSkus, referenceSellerView };
  };

  const getColumns = (): Array<IColumn> => {
    const productColumns: Array<IColumn> = [
      {
        title: __('Components.ProductsList.Table.Sku'),
        id: 'sku',
        width: '80px',
        element: (data: GenericProduct) => {
          return <S.TextBlack>{data.sku}</S.TextBlack>;
        },
      },
      {
        title: __('Components.ProductsList.Table.TypeVariety'),
        id: 'name',
        width: '150px',
        element: (data: GenericProduct) => {
          const { hasRepeatedSkus, referenceSellerView } = checkRepeatedClientAssociations(
            data,
            clientAssociations,
            products,
          );
          return (
            <S.ProductWrapper>
              <S.TextBlack>
                {data.productTitle ||
                  productService.getProductTypeVarietyDisplay(
                    data.type,
                    prodTypes[data.type]?.name || '',
                    data.variety,
                  )}
              </S.TextBlack>
              {hasRepeatedSkus ? (
                <S.WarningRow>
                  <S.WarningIcon name="Warning" disableHover={true} />
                  <S.WarningText>
                    {__('Components.OffersList.table.warning', {
                      reference: referenceSellerView ? referenceSellerView.reference?.name : '',
                    })}
                  </S.WarningText>
                </S.WarningRow>
              ) : null}
            </S.ProductWrapper>
          );
        },
      },
      ...(toggleAssociateProductsEnabled
        ? [
            {
              title: __('Components.OffersList.reference'),
              id: 'reference',
              width: '100px',
              info: __('Components.OffersList.table.tooltip'),
              element: (data: GenericProduct) => {
                const referenceSellerView = clientAssociations?.length
                  ? clientAssociations?.find(ca => ca.sku === data.sku)
                  : undefined;
                return referenceSellerView ? (
                  <S.ReferenceRow>
                    <S.TextBlack>{referenceSellerView?.reference.name}</S.TextBlack>
                    {editable ? (
                      <S.CloseIcon
                        name="Close"
                        onClick={async () => {
                          removeReference(data.sku, referenceSellerView.reference.id);
                          setHasChanges();
                        }}
                      />
                    ) : null}
                  </S.ReferenceRow>
                ) : editable ? (
                  <S.ReferenceCta onClick={() => setSelectedProduct(data)}>
                    {__('Components.OffersList.table.associate_reference')}
                  </S.ReferenceCta>
                ) : (
                  <S.ZeroCaseReference>{'-'}</S.ZeroCaseReference>
                );
              },
            },
          ]
        : []),
      {
        title: __('Components.ProductsList.Table.Origin'),
        id: 'origin',
        width: '100px',
        element: (data: GenericProduct) => {
          const { hasRepeatedSkus } = checkRepeatedClientAssociations(data, clientAssociations, products);
          return <S.TextBlack disabled={hasRepeatedSkus}>{data.origin}</S.TextBlack>;
        },
      },
      {
        title: __('Components.OffersList.table.total_availability'),
        id: 'availability',
        width: '100px',
        element: (data: GenericProduct) => {
          const totalAvailability = offeredProducts
            .filter(p => p.sku === data.sku && p.unlimitedQuantity === false)
            .reduce((acc, product) => (acc === 0 ? product.offeredQuantity : acc + product.offeredQuantity), 0);
          const isUnlimited = offeredProducts.every(p => p.sku === data.sku && p.unlimitedQuantity === true);
          const { hasRepeatedSkus } = checkRepeatedClientAssociations(data, clientAssociations, products);
          return (
            <S.TextBold disabled={hasRepeatedSkus}>
              {isUnlimited
                ? __('Components.OffersList.table.unlimited')
                : `${totalAvailability || '-'} ${unitTranslator(data.saleUnit, totalAvailability)}`}
            </S.TextBold>
          );
        },
      },
    ];

    if (editable) {
      // Append the 1st element
      productColumns.unshift({
        title: '',
        id: 'delete',
        width: '50px',
        element: (data: GenericProduct) => {
          return (
            <S.IconWrapper>
              <S.RemoveIcon
                name="Close"
                onClick={e => {
                  deleteProduct(data.sku);
                  setHasChanges();
                  e.stopPropagation();
                }}
              />
            </S.IconWrapper>
          );
        },
      });
    }

    if (addresses?.length > 0) {
      addresses?.map(address => {
        productColumns.push({
          title: __('Components.OffersList.table.unit_price'),
          id: 'price',
          width: '100px',
          cellClassName: 'offer-bordered-cell-left',
          element: (data: any, _, rowIdx: number) => {
            const priceValue =
              offeredProducts.find(p => p.sku === data.sku && (address.id === -1 || p.warehouseId === address.id))
                ?.offeredPrice || 0;
            const { hasRepeatedSkus } = checkRepeatedClientAssociations(data, clientAssociations, products);
            return editable ? (
              <Input
                id={`price-input-${rowIdx}`}
                name={'offeredPrice'}
                onBlur={(name, value: number) => {
                  setProductOffer(data.sku, name, value, address.id !== -1 ? address.id : undefined);
                  setHasChanges();
                }}
                value={priceValue}
                disabled={hasRepeatedSkus}
                zeroCase="0"
                precision={2}
                variableTextSingular={currency.getPricePerUnit(data.currency, data.priceUnit, data.weightUnit)}
                width="120px"
                type="number"
                textAlign="right"
                hasError={false}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            ) : (
              <S.TextBlack>{`${priceValue || '-'} ${currency.getPricePerUnit(
                data.currency,
                data.priceUnit,
                data.weightUnit,
              )}`}</S.TextBlack>
            );
          },
        });
        productColumns.push({
          title: __('Components.OffersList.table.daily_availability'),
          id: 'daily-availability',
          width: '100px',
          cellClassName: 'offer-bordered-cell-right',
          element: (data: any, _, rowIdx: number) => {
            const offeredProduct = offeredProducts.find(
              p => p.sku === data.sku && (address.id === -1 || p.warehouseId === address.id),
            );
            const unlimitedAvailability = offeredProduct?.unlimitedQuantity === true;
            const availabilityValue =
              offeredProducts.find(p => p.sku === data.sku && (address.id === -1 || p.warehouseId === address.id))
                ?.offeredQuantity || 0;
            if (editable) {
              const selectOptions = data.saleUnits.map(s => ({
                value: s,
                label: parsers.getUnitText(s, data.weightUnit, 0, i18n.getLanguageCode(i18n.default.currentLocale())),
              }));
              selectOptions.push({ value: 'unlimited', label: __('Components.OffersList.table.unlimited') });
              const { hasRepeatedSkus } = checkRepeatedClientAssociations(data, clientAssociations, products);
              return (
                <S.InputRow>
                  {unlimitedAvailability ? null : (
                    <Input
                      id={`availability-input-${rowIdx}`}
                      name={'offeredQuantity'}
                      onBlur={(name, value: number) => {
                        setProductOffer(data.sku, name, +value, address.id !== -1 ? address.id : undefined);
                        setHasChanges();
                      }}
                      disabled={hasRepeatedSkus}
                      value={availabilityValue}
                      containerMargin="0"
                      width="70px"
                      type="number"
                      textAlign="right"
                      hasError={false}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    />
                  )}
                  <Select
                    containerMargin={unlimitedAvailability ? '0' : '0 6px'}
                    value={unlimitedAvailability ? 'unlimited' : offeredProduct?.offeredQuantityUnit}
                    name={`offeredQuantityUnit`}
                    dropUp={rowIdx >= products.length - 2 ? true : false}
                    options={selectOptions}
                    zIndex={3}
                    disabled={hasRepeatedSkus}
                    onChange={(name, value) => {
                      if (value === 'unlimited') {
                        setProductOffer(
                          data.sku,
                          'unlimitedQuantity',
                          true,
                          address.id !== -1 ? address.id : undefined,
                        );
                      } else {
                        setProductOffer(data.sku, name, value, address.id !== -1 ? address.id : undefined);
                        if (unlimitedAvailability) {
                          setProductOffer(
                            data.sku,
                            'unlimitedQuantity',
                            false,
                            address.id !== -1 ? address.id : undefined,
                          );
                        }
                      }
                      setHasChanges();
                    }}
                    width={unlimitedAvailability ? '165px' : '90px'}
                  />
                </S.InputRow>
              );
            } else {
              return (
                <S.TextBlack>
                  {unlimitedAvailability
                    ? __('Components.OffersList.table.unlimited')
                    : `${availabilityValue} ${parsers.getUnitText(
                        data.saleUnit,
                        data.weightUnit,
                        availabilityValue,
                        i18n.getLanguageCode(i18n.default.currentLocale()),
                      )}`}
                </S.TextBlack>
              );
            }
          },
        });
      });
    }

    return productColumns;
  };

  const columns = [
    { value: 'sku', label: 'sku' },
    { value: 'name', label: 'name' },
    { value: 'origin', label: 'origin' },
    { value: 'availability', label: __('Components.OffersList.table.total_availability') },
    ...(toggleAssociateProductsEnabled ? [{ value: 'reference', label: __('Components.OffersList.reference') }] : []),
  ];

  const addressColumns = addresses?.map(address => {
    return { value: address?.city, label: address?.city };
  });
  columns.push(...addressColumns);

  const headerColumnsAddress = addresses?.map((address, i) => {
    return {
      title: address?.city,
      id: 'warehouse' + i,
      width: '200px',
      colSpan: 2,
      isGrouped: true,
      isBlue: address.id !== -1,
      hasCustomCell: true,
      cellClassName: 'offer-bordered-header',
      renderCell: () => {
        return address.id !== -1 ? (
          <S.AddressWrapper>
            <S.AddressCell>
              <S.TextHeaderBold>{address.displayName}</S.TextHeaderBold>
              <S.TextHeader>{`${address.city}, ${address.zip}`}</S.TextHeader>
            </S.AddressCell>
            <S.FontIconWrapper>
              <FontIcon
                name="Close"
                onClick={() => {
                  removeAddress(address.id);
                  setHasChanges();
                }}
              />
            </S.FontIconWrapper>
          </S.AddressWrapper>
        ) : (
          <S.AddressWrapper>
            <S.AddressIcon>
              <FontIcon name="Address" />
            </S.AddressIcon>
            <S.TextZeroCase>
              {editable
                ? totalAddresses.length > 0
                  ? __('Components.OffersList.table.no_address_selected')
                  : __('Components.OffersList.table.no_address_added')
                : __('Components.OffersList.table.no_address')}
            </S.TextZeroCase>
          </S.AddressWrapper>
        );
      },
    };
  });

  const headerColumns = [
    editable
      ? {
          title: '',
          id: '',
          width: '20px',
          colSpan: 3,
          renderCell: () => {
            return (
              <S.CtaButton type="secondary" onClick={searchProducts}>
                <S.AddIcon name="Add-more" />
                {__('Components.OffersList.table.add_products')}
              </S.CtaButton>
            );
          },
        }
      : {
          title: '',
          id: '',
          colSpan: 2,
        },
    {
      title: '',
      id: '',
    },
    {
      title: '',
      id: '',
    },
    ...(toggleAssociateProductsEnabled
      ? [
          {
            title: '',
            id: '',
          },
        ]
      : []),
  ] as Array<any>;
  headerColumns.push(...(headerColumnsAddress as any));

  const handleClick = (value: any) => {
    if (value && value.sku) {
      const offeredProduct = offeredProducts.find(p => p.sku === value.sku);
      if (offeredProduct && offeredProduct.productSnapshot) {
        history.push(
          getPath({
            path: ROUTE_PATHS.WORKSPACE_PRODUCTS,
            workspaceId: String(workspaceId),
            productId: offeredProduct.productSnapshot.id,
          }),
        );
      }
    }
  };
  return (
    <S.Container>
      <S.ProductsContainer>
        <S.TitleText>{__('Components.OffersList.table.articles', { count: products.length })}</S.TitleText>
      </S.ProductsContainer>
      {editable ? (
        <S.ProductsContainer>
          {!products || products.length === 0 ? (
            <S.CtaButton type="secondary" onClick={searchProducts}>
              <S.AddIcon name="Add-more" />
              {__('Components.OffersList.table.add_products')}
            </S.CtaButton>
          ) : null}
          <S.BlueCta onClick={() => setShowSearchAddress(!showSearchAddress)}>
            <S.AddIcon name="Add-more" /> {__('Components.OffersList.table.add_address')}
          </S.BlueCta>
        </S.ProductsContainer>
      ) : null}

      <S.TableContainer>
        <Table
          selectable={false}
          columns={getColumns()}
          headerColumns={headerColumns}
          emptyText=""
          onClickRow={() => null}
          values={products}
          productColumns={columns}
          showStickyHeader={true}
          rowCursor="default"
        />
      </S.TableContainer>
      {showSearchAddress ? renderModalSearchAddress() : null}
      {selectedProduct ? renderAssociationModal() : null}
    </S.Container>
  );

  function renderModalSearchAddress() {
    return (
      <AddressModal
        isBuyerAddress={true}
        addresses={totalAddresses?.filter(a => !addresses?.find(ad => ad.id === a.id)) || []}
        catalogId={+workspaceId}
        onClose={() => setShowSearchAddress(false)}
        title={__('Components.OrderDetails.transport.address_title')}
        subtitle={__('Components.OffersList.table.address_subtitle')}
        onSelectAddress={(a: IAddress) => {
          addAddress(a);
          setShowSearchAddress(false);
          setHasChanges();
        }}
      />
    );
  }
  function renderAssociationModal() {
    const referencesOptions = references.map((r: any) => ({
      value: r.id,
      label: r.name,
    }));
    const channelToShow = Object.values(channels).find(
      chan => chan.type === 'private' && chan.members.find(cm => cm.id === +client?.userId),
    );
    const selectedOption = referencesOptions.find(a => a.value === associatedReferenceId);
    return (
      <ActionsModal
        minHeight="300px"
        width="500px"
        onClose={() => {
          setSelectedProduct(null);
          setAssociatedReferenceId(null);
        }}
        title={
          referencesOptions.length > 0 ? __('Components.OffersList.table.modal.title', { supplier: client.name }) : ''
        }
        subtitle={referencesOptions.length > 0 ? __('Components.OffersList.table.modal.text') : ''}
        overFlowVisible={true}
      >
        {referencesOptions.length > 0 ? (
          <>
            <S.InputTitle>{__('Components.OffersList.table.modal.input_title')}</S.InputTitle>
            <Select
              containerMargin={'0'}
              value={selectedOption?.value}
              name="reference-name"
              options={referencesOptions}
              onChange={(name, id) => {
                setAssociatedReferenceId(+id);
              }}
              width="100%"
            />
            <S.ModalRow>
              <S.ModalButton
                type="secondary"
                onClick={() => {
                  setSelectedProduct(null);
                  setAssociatedReferenceId(null);
                }}
              >
                {__('Components.OrderDetails.issues_modal.cancel')}
              </S.ModalButton>
              <S.ModalButton
                disabled={false}
                onClick={async () => {
                  await associateProduct(associatedReferenceId, selectedProduct.sku);
                  setSelectedProduct(null);
                  setAssociatedReferenceId(null);
                  setHasChanges();
                }}
              >
                {__('Components.OrderDetails.issues_modal.cta')}
              </S.ModalButton>
            </S.ModalRow>
          </>
        ) : (
          <EmptyListResource
            imageUrl={IMAGES.emptyBox}
            showButton={true}
            text={__('Components.OffersList.table.modal.zero_case_title')}
            text2={__('Components.OffersList.table.modal.zero_case_text')}
            loading={loadingReferences}
            loadingText={__('Components.OffersList.table.modal.loading_case_title')}
            loadingText2={__('Components.OffersList.table.modal.loading_case_text')}
            buttonText={__('Components.OffersList.table.modal.cta_chat')}
            buttonAction={() => {
              if (channelToShow) {
                dispatch(
                  navActions.navigateChannelByPath(ROUTE_PATHS.CONTACT, channelToShow.id, (path: string) =>
                    history.push(path),
                  ),
                );
              }
            }}
            imageSize="160px"
          />
        )}
      </ActionsModal>
    );
  }
  function removeReference(sku: string, associatedReferenceId: number) {
    dispatch(
      modalActions.modalOpen(
        __('Components.OffersList.table.remove_ref_text'),
        async () => {
          await removeAssociation(associatedReferenceId, sku);
          setSelectedProduct(null);
          setAssociatedReferenceId(null);
          dispatch(modalActions.modalClose());
        },
        {
          showCancelButton: true,
          buttonText: __('Components.Referential.reference.delete'),
          text2: __('Components.OffersList.table.remove_ref_title'),
          actionType: 'dangerous',
          icon: IMAGES.error,
        },
        'nice',
      ),
    );
  }
};

export default OfferedProductTable;
