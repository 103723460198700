import { __, utils, WORKING_STATUS } from 'common-services';
import * as React from 'react';

import FontIcon from '../FontIcon';
import RadioInput from '../RadioInput';
import * as S from './FacetFilter.styled';

export interface IFilterOption {
  name: string;
  key: string;
  count?: number;
  checked?: boolean;
  image?: string;
  type?: string;
  workingStatus?: WORKING_STATUS;
  hideAmount?: boolean;
  amountType?: IAmountType;
}

export type IAmountType = 'order' | 'mapping' | 'format' | 'file';

export interface IProps {
  children?: React.ReactNode;
  // Title show when folded or unfolded
  title: string;
  // Options to be shown (can be unsorted)
  options: Array<IFilterOption>;
  // Number of initially shown options
  numVisibleOptions: number;
  // First render should be folded or not
  startFolded: boolean;
  // Handler for change
  onChange?: (selected: Array<string> | string) => void;
  // Only can select one at the same time of the fields
  isRadio?: boolean;
}

interface IState {
  isFold: boolean;
  showAllOptions: boolean;
  sorted: Array<IFilterOption>;
}

// FacetFilter is the component for showing multiple checkboxes
// and listening for changes.
// Support fold/unfold and different initial settings
export default class FacetFilter extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isFold: props.startFolded,
      showAllOptions: props.numVisibleOptions > props.options.length,
      sorted: props.isRadio
        ? props.options
        : props.options.sort((a: IFilterOption, b: IFilterOption) => (b.count || 0) - (a.count || 0)),
    };
  }

  public componentDidUpdate(prevProps: IProps) {
    const { isRadio, options } = this.props;
    if (prevProps.options !== options) {
      this.setState({
        sorted: isRadio
          ? options
          : options.sort((a: IFilterOption, b: IFilterOption) => (b.count || 0) - (a.count || 0)),
      });
    }
  }

  public render() {
    const { children, options, numVisibleOptions, title, isRadio, onChange } = this.props;
    const { sorted, showAllOptions, isFold } = this.state;
    const total = options.length;
    let visible = sorted;
    if (!showAllOptions) {
      visible = visible.slice(0, numVisibleOptions);
    }
    return (
      <S.Container>
        <S.Top onClick={() => this.setState({ isFold: !isFold })}>
          <S.Title>{title}</S.Title>
          <S.Chevron>
            <FontIcon name={isFold ? 'Right' : 'Down'} disableHover={true} />
          </S.Chevron>
        </S.Top>
        {!isFold ? (
          <React.Fragment>
            <S.List>
              {visible.map((option: IFilterOption, index: number) => (
                <FacetFilterOption
                  key={option.key}
                  isRadio={isRadio}
                  option={option}
                  onChange={() => onChange(isRadio ? option.key : getOptionsSelected(options, option.key))}
                />
              ))}
              {showAllOptions ? children : null}
            </S.List>
            {numVisibleOptions < total ? (
              <S.Link onClick={() => this.setState({ showAllOptions: !showAllOptions })}>
                {showAllOptions ? __('Constants.view_less') : __('Constants.view_all', { count: total })}
              </S.Link>
            ) : null}
          </React.Fragment>
        ) : null}
      </S.Container>
    );
  }
}

// FacetFilterOption is a subcomponent for one option inside a FaceFilter (check + text)
export const FacetFilterOption: React.FC<{
  isRadio?: boolean;
  onChange: () => void;
  option: IFilterOption;
}> = ({ option, onChange, isRadio = false }) => {
  return (
    <S.Option onClick={onChange}>
      <RadioInput isRadio={isRadio} checked={option.checked} value={option.key} />
      {option.image || option.type ? (
        <>
          <S.Avatar
            avatarColor={utils.getAvatarColor(option.name)}
            img={option.image}
            text={option.name}
            type={option.type}
            workingStatus={option.workingStatus}
            bubbleSize={15}
          />
          <S.TextColumn>
            <S.OptionText>{option.name}</S.OptionText>
            <S.Amount>{getAmountLiteral(option.amountType || 'order', option.count || 0)}</S.Amount>
          </S.TextColumn>
        </>
      ) : (
        <S.OptionText>
          {option.name} {option.hideAmount ? null : `(${option.count || 0})`}
        </S.OptionText>
      )}
    </S.Option>
  );
};

function getAmountLiteral(amountType: IAmountType, amount: number): string {
  switch (amountType) {
    case 'mapping':
      return __('Facets.count_mappings', {
        count: amount || 0,
      });

    case 'format':
      return __('Facets.count_formats', {
        count: amount || 0,
      });

    case 'file':
      return __('Facets.count_files', {
        count: amount || 0,
      });

    default:
      return __('Facets.count_orders', {
        count: amount || 0,
      });
  }
}

// getOptionsSelected computes which data should be notified to callback handler
// it takes previously selected + the new one (can be included or excluded from initial list)
function getOptionsSelected(options: Array<IFilterOption>, newKey: string): Array<string> {
  const selected: Array<string> = options.filter((o: IFilterOption) => o.checked).map((o: IFilterOption) => o.key);
  const found = selected.indexOf(newKey);
  if (found === -1) {
    selected.push(newKey);
  } else {
    selected.splice(found, 1);
  }
  return selected;
}
