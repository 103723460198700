import { __, modalActions, utils } from 'common-services';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import config from '../../../../../../bindings/config';
import { IMAGES } from '../../../../../assets';
import { api } from '../../../../../store';
import { Radio, Select, Switch } from '../../../../atoms';
import { FormSection, InputWithLabel } from '../../../../molecules';
import WorkspacePdfFormat from '../../../WorkspacePdfFormat';
import { getSortItemsByOptions } from '../../WorkspaceSettingsSeller.utils';
import * as S from './OrderPreferences.styled';

interface IProps {
  myId: number;
  match: any;
  catalog: IWorkspace;
  editMode: boolean;
  isLast: boolean;
  isPro: boolean;
  sectionRef: React.RefObject<HTMLDivElement>;
  updateCatalog: (catalog: IWorkspace) => void;
  workspaceId: number;
  supportAction?: () => void;
}

const OrderPreferences: React.FC<IProps> = ({
  catalog,
  editMode,
  isLast,
  isPro,
  match,
  myId,
  sectionRef,
  updateCatalog,
  workspaceId,
  supportAction,
}) => {
  const dispatch = useDispatch();
  const [pdfConfigToEdit, setPdfConfigToEdit] = React.useState<IOrderDownloadOptions>();
  const [pdfFormats, setPdfFormats] = React.useState<Array<IOrderDownloadOptions>>([]);
  React.useEffect(() => {
    if (myId && catalog) {
      api.sellerWorkspace.getSettingsDownloadPdf(myId, catalog.id).then(formats => {
        setPdfFormats(formats || []);
      });
    }
  }, [myId, catalog]);

  const numHeads = editMode ? 0 : 1;
  return pdfConfigToEdit ? (
    <WorkspacePdfFormat
      pdfFormat={pdfConfigToEdit}
      history={history}
      location={location}
      match={match}
      onBack={() => setPdfConfigToEdit(undefined)}
      updateFormat={format => {
        const newFormats = [...pdfFormats];
        const idx = newFormats.findIndex(f => f.id === format.id);
        if (idx !== -1) {
          newFormats.splice(idx, 1);
        }
        newFormats.push(format);
        setPdfFormats(newFormats);
      }}
      deleteFormat={() => {
        dispatch(
          modalActions.modalOpen(
            __('PdfFormat.confirm_remove'),
            async () => {
              await api.sellerWorkspace.deleteSettingsDownloadPdf(
                myId,
                pdfConfigToEdit.workspaceId,
                pdfConfigToEdit.id,
              );
              const newFormats = [...pdfFormats];
              const idx = newFormats.findIndex(f => f.id === pdfConfigToEdit.id);
              if (idx !== -1) {
                newFormats.splice(idx, 1);
              }
              dispatch(modalActions.modalClose());
              setPdfFormats(newFormats);
              setPdfConfigToEdit(undefined);
            },
            {
              buttonText: __('Components.Modal.delete'),
            },
          ),
        );
      }}
    />
  ) : isPro ? (
    <FormSection
      sectionRef={sectionRef}
      id="order"
      title={__('WorkspaceSettings.Menu.Order.title')}
      numHeaders={isLast ? numHeads : undefined}
    >
      <InputWithLabel
        isRequired={true}
        label={__('WorkspaceSettings.Order.download_pdf.title')}
        description={__('WorkspaceSettings.Order.download_pdf.description')}
        disabled={!editMode}
        asColumn={true}
      >
        <S.Formats hasContent={!!pdfFormats.length}>
          {pdfFormats.map(f => (
            <S.FormatItem key={f.id} onClick={() => setPdfConfigToEdit(f)}>
              <S.FormatIcon name="Document" />
              <S.FormatColumn>
                <S.FormatTitle>{f.name}</S.FormatTitle>
                <S.FormatText>
                  {f.isExcel ? 'Excel' : null}
                  {f.isExcel && f.isPdf ? ', ' : null}
                  {f.isPdf ? 'PDF' : null}
                </S.FormatText>
              </S.FormatColumn>
              <S.FormatArrow name="Right" />
            </S.FormatItem>
          ))}
        </S.Formats>
      </InputWithLabel>
      <S.ButtonAdd
        type="link"
        iconName="Add-more"
        iconSize="20px"
        withoutPadding
        disabled={!editMode}
        onClick={() =>
          setPdfConfigToEdit({
            id: 0,
            workspaceId,
            name: '',
            isExcel: true,
            isPdf: true,
            config: {
              columns: [
                { field: 'sku', column: 'A', displayName: '' },
                { field: 'description', column: 'B', displayName: '' },
                { field: 'price', column: 'C', displayName: '' },
                { field: 'amount', column: 'D', displayName: '' },
                { field: 'total_price', column: 'E', displayName: '' },
              ],
              load: true,
              transportDetails: true,
              incoterms: true,
              totalPrice: true,
              orderComments: false,
              fontSize: 'small',
              showBorders: false,
            },
            createdAt: 0,
            updatedAt: 0,
          })
        }
      >
        {__('WorkspaceSettings.PdfConfig.Add')}
      </S.ButtonAdd>
      <InputWithLabel
        isRequired={true}
        label={__('WorkspaceSettings.Order.sort_items.title')}
        description={__('WorkspaceSettings.Order.sort_items.description')}
        disabled={!editMode}
      >
        <Select
          name="sort_items_by"
          value={catalog.sortItemsBy}
          options={getSortItemsByOptions()}
          onChange={(n, v) => {
            if (v !== catalog.sortItemsBy) {
              dispatch(
                modalActions.modalOpen(
                  __('WorkspaceSettings.Order.sort_items.modal_title', {
                    option: getSortItemsByOptions().find(o => o.value === v)?.label,
                  }),
                  () => {
                    updateCatalog({ ...catalog, sortItemsBy: v as SortItemFilter });
                    dispatch(modalActions.modalClose());
                  },
                  {
                    icon: IMAGES.informativePineapple,
                    buttonText: __('WorkspaceSettings.Order.sort_items.modal_cta'),
                    showCancelButton: true,
                    text2: __('WorkspaceSettings.Order.sort_items.modal_description'),
                  },
                  'nice',
                ),
              );
            }
          }}
          disabled={!editMode}
          containerMargin="4px 0"
          width="40%"
        />
      </InputWithLabel>
      <InputWithLabel
        isRequired={true}
        label={__('WorkspaceSettings.Magic.label')}
        description={__('WorkspaceSettings.Magic.description')}
        disabled={!editMode}
        asColumn={true}
      >
        <Radio
          name={'magic_version'}
          direction={'column'}
          options={[
            {
              label: __('WorkspaceSettings.Magic.ean_code'),
              value: 'ean_code',
            },
            {
              label: __('WorkspaceSettings.Magic.sku'),
              value: 'sku',
            },
          ]}
          containerMargin={'4px 0'}
          tooltipType={'image'}
          alignItems={'flex-start'}
          styleLabel={{ margin: '5px 0' }}
          itemsChecked={[catalog.productMappingStrategy]}
          onChange={(key, value: 'ean_code' | 'sku', error) => {
            if (!error) {
              updateCatalog({ ...catalog, productMappingStrategy: value });
            }
          }}
          disabled={!editMode}
        />
      </InputWithLabel>
      {config.TOGGLE_BOXES_PER_PALLET.enabled ? (
        <InputWithLabel
          isRequired={true}
          label={__('WorkspaceSettings.Menu.Palletization.title')}
          description={__('WorkspaceSettings.Menu.Palletization.description')}
          disabled={!editMode}
          asColumn={true}
        >
          <S.SwitchRow>
            <S.SwitchLabel>{__('WorkspaceSettings.Menu.Palletization.switch_label')}</S.SwitchLabel>
            <Switch
              name="palletization"
              isChecked={catalog.mandatoryPalletizationByClient}
              disabled={!editMode}
              onChange={(n, checked) => updateCatalog({ ...catalog, mandatoryPalletizationByClient: checked })}
            />
          </S.SwitchRow>
          <S.SwitchDescription>
            {catalog.mandatoryPalletizationByClient
              ? __('WorkspaceSettings.Menu.Palletization.switch_on')
              : __('WorkspaceSettings.Menu.Palletization.switch_off')}
          </S.SwitchDescription>
          {!catalog.mandatoryPalletizationByClient ? (
            <S.SwitchDescription>
              {
                utils.formatText(__('WorkspaceSettings.Menu.Palletization.support'), (s, idx) => (
                  <S.SwitchLink key={s + idx} onClick={() => supportAction?.()}>
                    {s}
                  </S.SwitchLink>
                )) as any // TYPEERROR
              }
            </S.SwitchDescription>
          ) : null}
        </InputWithLabel>
      ) : null}
      {catalog?.hasIntegrationExport ? (
        <InputWithLabel
          isRequired={true}
          label={__('WorkspaceSettings.Order.order_erp.title')}
          description={__('WorkspaceSettings.Order.order_erp.description')}
          disabled={!editMode}
          footerText={
            catalog.manuallyPushOrdersToERP
              ? __('WorkspaceSettings.Order.order_erp.footer_manual')
              : __('WorkspaceSettings.Order.order_erp.footer_auto')
          }
        >
          <Select
            name="send_order_erp"
            value={catalog.manuallyPushOrdersToERP ? 'manual' : 'automatic'}
            options={[
              { value: 'automatic', label: __('WorkspaceSettings.Order.order_erp.automatic') },
              { value: 'manual', label: __('WorkspaceSettings.Order.order_erp.manual') },
            ]}
            onChange={(n, v) => {
              if (
                (catalog.manuallyPushOrdersToERP && v === 'automatic') ||
                (!catalog.manuallyPushOrdersToERP && v === 'manual')
              ) {
                dispatch(
                  modalActions.modalOpen(
                    v === 'manual'
                      ? __('WorkspaceSettings.Order.order_erp.modal.manual.title')
                      : __('WorkspaceSettings.Order.order_erp.modal.automatic.title'),
                    () => {
                      updateCatalog({ ...catalog, manuallyPushOrdersToERP: v === 'manual' });
                      dispatch(modalActions.modalClose());
                    },
                    {
                      text2:
                        v === 'manual'
                          ? __('WorkspaceSettings.Order.order_erp.modal.manual.subtitle')
                          : __('WorkspaceSettings.Order.order_erp.modal.automatic.subtitle'),
                      buttonText: __('WorkspaceSettings.Order.order_erp.modal.cta'),
                      showCancelButton: true,
                      buttonCancelText: __('WorkspaceSettings.Order.order_erp.modal.cancel'),
                      closeable: true,
                      icon: IMAGES.informativePineapple,
                    },
                    'nice',
                  ),
                );
              }
            }}
            disabled={!editMode}
            containerMargin="4px 0"
            width="40%"
          />
        </InputWithLabel>
      ) : null}
    </FormSection>
  ) : (
    <PdfNoPro
      catalog={catalog}
      editMode={editMode}
      isLast={isLast}
      sectionRef={sectionRef}
      updateCatalog={updateCatalog}
    />
  );
};

const PdfNoPro = React.memo(
  ({
    catalog,
    editMode,
    sectionRef,
    updateCatalog,
    isLast,
    supportAction,
  }: {
    catalog: IWorkspace;
    editMode: boolean;
    sectionRef: React.RefObject<HTMLDivElement>;
    updateCatalog: (catalog: IWorkspace) => void;
    isLast: boolean;
    supportAction?: () => void;
  }) => {
    const numHeads = editMode ? 0 : 1;
    const dispatch = useDispatch();
    return (
      <FormSection
        sectionRef={sectionRef}
        id="order"
        title={__('WorkspaceSettings.Menu.Order.title')}
        numHeaders={isLast ? numHeads : undefined}
      >
        <InputWithLabel isRequired={true} footerText={__('WorkspaceSettings.Pdf.description')} disabled={!editMode}>
          <S.SwitchRow>
            <S.SwitchLabel>{__('WorkspaceSettings.Pdf.switch')}</S.SwitchLabel>
            <Switch
              name="showPricesPdf"
              isChecked={catalog.pdfShowPrices}
              onChange={(n, checked) => updateCatalog({ ...catalog, pdfShowPrices: checked })}
              disabled={!editMode}
              selectable={false}
            />
          </S.SwitchRow>
        </InputWithLabel>
        <InputWithLabel
          isRequired={true}
          label={__('WorkspaceSettings.PdfFormat.label')}
          disabled={!editMode}
          asColumn={true}
        >
          <Radio
            name={'pdf_version'}
            direction={'column'}
            options={[
              {
                label: __('WorkspaceSettings.PdfFormat.comfortable'),
                value: 'comfortable',
                tooltip: IMAGES.previewPDFComfortable,
              },
              {
                label: __('WorkspaceSettings.PdfFormat.compact'),
                value: 'compact',
                tooltip: IMAGES.previewPDFCompact,
              },
            ]}
            containerMargin={'4px 0'}
            tooltipType={'image'}
            alignItems={'flex-start'}
            styleLabel={{ margin: '5px 0' }}
            itemsChecked={[catalog.pdfVersion]}
            onChange={(key, value: 'compact' | 'comfortable', error) => {
              if (!error) {
                updateCatalog({ ...catalog, pdfVersion: value });
              }
            }}
            disabled={!editMode}
          />
        </InputWithLabel>
        <InputWithLabel
          isRequired={true}
          label={__('WorkspaceSettings.Order.sort_items.title')}
          description={__('WorkspaceSettings.Order.sort_items.description')}
          disabled={!editMode}
        >
          <Select
            name="sort_items_by"
            value={catalog.sortItemsBy}
            options={getSortItemsByOptions()}
            onChange={(n, v) => {
              if (v !== catalog.sortItemsBy) {
                dispatch(
                  modalActions.modalOpen(
                    __('WorkspaceSettings.Order.sort_items.modal_title', {
                      option: getSortItemsByOptions().find(o => o.value === v)?.label,
                    }),
                    () => {
                      updateCatalog({ ...catalog, sortItemsBy: v as SortItemFilter });
                      dispatch(modalActions.modalClose());
                    },
                    {
                      icon: IMAGES.informativePineapple,
                      buttonText: __('WorkspaceSettings.Order.sort_items.modal_cta'),
                      showCancelButton: true,
                      text2: __('WorkspaceSettings.Order.sort_items.modal_description'),
                    },
                    'nice',
                  ),
                );
              }
            }}
            disabled={!editMode}
            containerMargin="4px 0"
            width="40%"
          />
        </InputWithLabel>
        {config.TOGGLE_BOXES_PER_PALLET.enabled ? (
          <InputWithLabel
            isRequired={true}
            label={__('WorkspaceSettings.Menu.Palletization.title')}
            description={__('WorkspaceSettings.Menu.Palletization.description')}
            disabled={!editMode}
            asColumn={true}
          >
            <S.SwitchRow>
              <S.SwitchLabel>{__('WorkspaceSettings.Menu.Palletization.switch_label')}</S.SwitchLabel>
              <Switch
                name="palletization"
                isChecked={catalog.mandatoryPalletizationByClient}
                disabled={!editMode}
                onChange={(n, checked) => updateCatalog({ ...catalog, mandatoryPalletizationByClient: checked })}
              />
            </S.SwitchRow>
            <S.SwitchDescription>
              {catalog.mandatoryPalletizationByClient
                ? __('WorkspaceSettings.Menu.Palletization.switch_on')
                : __('WorkspaceSettings.Menu.Palletization.switch_off')}
            </S.SwitchDescription>
            {!catalog.mandatoryPalletizationByClient ? (
              <S.SwitchDescription>
                {
                  utils.formatText(__('WorkspaceSettings.Menu.Palletization.support'), (s, idx) => (
                    <S.SwitchLink key={s + idx} onClick={() => supportAction?.()}>
                      {s}
                    </S.SwitchLink>
                  )) as any // TYPEERROR
                }
              </S.SwitchDescription>
            ) : null}
          </InputWithLabel>
        ) : null}
      </FormSection>
    );
  },
);

export default React.memo(OrderPreferences);
