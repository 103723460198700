import {
  IWidgetType,
  IOrderSummariesResponse,
  ITopUsersResponse,
  ITopProductsResponse,
  IBarChartsResponse,
} from 'common-services';

interface DashboardData {
  summaryData: IOrderSummariesResponse | undefined;
  topUsersData: ITopUsersResponse | undefined;
  topProductsData: ITopProductsResponse | undefined;
  barChartsData: IBarChartsResponse | undefined;
}

interface DashboardDataResult {
  data: DashboardData;
  hasSummaryData: boolean;
  hasTopProductsData: boolean;
  hasBarChartsData: boolean;
  hasTopUsersData: boolean;
}

export const getDashboardData = (dashboard: IOrderDashboard | null): DashboardDataResult => {
  const data: DashboardData = {
    summaryData: undefined,
    topUsersData: undefined,
    topProductsData: undefined,
    barChartsData: undefined,
  };

  if (!dashboard || !Object.keys(dashboard.widgets).length) {
    return {
      data,
      hasSummaryData: false,
      hasTopProductsData: false,
      hasBarChartsData: false,
      hasTopUsersData: false,
    };
  }

  data.summaryData = dashboard.widgets[IWidgetType.ORDER_SUMMARIES] as IOrderSummariesResponse;
  data.topUsersData = dashboard.widgets[IWidgetType.TOP_USERS] as ITopUsersResponse;
  data.topProductsData = dashboard.widgets[IWidgetType.TOP_PRODUCTS] as ITopProductsResponse;
  data.barChartsData = dashboard.widgets[IWidgetType.ORDER_BAR_CHARTS] as IBarChartsResponse;

  const hasSummaryData = !!data.summaryData && !!data.summaryData.count;
  const hasTopProductsData =
    !!data.topProductsData && !!data.topProductsData.products && data.topProductsData.products.length > 0;
  const hasBarChartsData = !!data.barChartsData && data.barChartsData.length > 0;
  const hasTopUsersData = !!data.topUsersData && !!data.topUsersData.users && data.topUsersData.users.length > 0;

  return { data, hasSummaryData, hasTopProductsData, hasBarChartsData, hasTopUsersData };
};
