import { IToggle, parseToggle } from 'common-services';

import getEnvironments from '../getEnvironments';

// Config interface with all possible config values
interface Config {
  API_URL: string;
  APP_ENV: string;
  REVISION: string;
  DEEPLINK_URL: string;
  EMAIL_DOMAIN_ADDRESS: string;
  ENVIRONMENT: string;
  FIREBASE_APP_ID: string;
  FIREBASE_MEASUREMENT_ID: string;
  GOOGLE_PLACES: string;
  REALTIME_URL: string;
  SEARCH_PREFIX: string;
  SEGMENT_KEY: string;
  SENTRY_TRACK_URL: string;
  TOGGLE_ATHINA: IToggle;
  TOGGLE_PUSH_NOTIFICATIONS: IToggle;
  TOGGLE_PUBLIC_CHAT: IToggle;
  TOGGLE_SUSTAINABILITY: IToggle;
  TOGGLE_PRODUCT_LOT: IToggle;
  TOGGLE_SUSTAINABILITY_HOVER: IToggle;
  TOGGLE_PRODUCT_WAREHOUSE: IToggle;
  TOGGLE_EDI_TABS: IToggle;
  TOGGLE_PRICE_MISMATCH: IToggle;
  TOGGLE_MAPPING_V1: IToggle;
  TOGGLE_MARGINS: IToggle;
  TOGGLE_SEARCH_ADDRESS: IToggle;
  TOGGLE_CLIENT_CUSTOM_COLUMNS: IToggle;
  TOGGLE_CLIENT_CHAT: IToggle;
  TOGGLE_BULK_CHANGES_PRICEGROUPS: IToggle;
  TOGGLE_MO_FROM_CHAT: IToggle;
  TOGGLE_MO_PRICES_INFO: IToggle;
  TOGGLE_PRICEGROUPS_CUSTOM_COLUMNS: IToggle;
  TOGGLE_UNIVERSITY: IToggle;
  TOGGLE_MINI_CHAT: IToggle;
  TOGGLE_SUPPLIER_FILTER: IToggle;
  TOGGLE_NO_WORKSPACE_MODAL: IToggle;
  TOGGLE_EDIFACT_CODE: IToggle;
  TOGGLE_ORDER_ITEM_ISSUE: IToggle;
  TOGGLE_CONSENTIO_PRODUCT_ID: IToggle;
  TOGGLE_CART_ICON: IToggle;
  TOGGLE_NEW_SALES_UNITS: IToggle;
  TOGGLE_REQUEST_ORDER: IToggle;
  TOGGLE_NEW_CART_CARD: IToggle;
  TOGGLE_CLIENT_CURRENCY: IToggle;
  TOGGLE_PRODUCT_TOOLTIP: IToggle;
  TOGGLE_BOXES_PER_PALLET: IToggle;
  TOGGLE_LOAD_DATE: IToggle;
  TOGGLE_BUYER_OFFER_TABLE: IToggle;
  TOGGLE_SELLER_OFFER_TABLE: IToggle;
  TOGGLE_DISCARD_MO: IToggle;
  TOGGLE_ORDER_PRICE_UNITS: IToggle;
  TOGGLE_MAPPINGS_PER_CLIENT: IToggle;
  TOGGLE_CRUD_REFERENCES: IToggle;
  TOGGLE_RANK_PRICEGROUPS: IToggle;
  TOGGLE_REPEAT_OFFER: IToggle;
  TOGGLE_PRODUCTS_BUYER_REFERENCES: IToggle;
  TOGGLE_CANT_REPEAT_NOR_DRAFT_NOR_HISTORY: IToggle;
  WEB_URL: string;
}

// transform inputConfig in Config to have structured toggles
declare var global: { _env_: typeof config };
const skipValidation = !global._env_?.APP_ENV || global._env_.APP_ENV === 'production';
const ENV =
  global._env_ ||
  new Proxy({} as any, {
    get(target, name) {
      return name in target ? target[name] : 'disabled';
    },
  });

global._env_ = {
  ...global._env_,
  ...process.env,
};

// Common config for toggles
const commonConfig: Partial<Config> = {
  TOGGLE_PUSH_NOTIFICATIONS: parseToggle('enabled', 'TOGGLE_PUSH_NOTIFICATIONS', skipValidation),
  TOGGLE_PRODUCT_WAREHOUSE: parseToggle('enabled', 'TOGGLE_PRODUCT_WAREHOUSE', skipValidation),
  TOGGLE_MAPPING_V1: parseToggle('enabled', 'TOGGLE_MAPPING_V1', skipValidation),
  TOGGLE_SEARCH_ADDRESS: parseToggle('enabled', 'TOGGLE_SEARCH_ADDRESS', skipValidation),
  TOGGLE_CLIENT_CUSTOM_COLUMNS: parseToggle('enabled', 'TOGGLE_CLIENT_CUSTOM_COLUMNS', skipValidation),
  TOGGLE_CLIENT_CHAT: parseToggle('enabled', 'TOGGLE_CLIENT_CHAT', skipValidation),
  TOGGLE_BULK_CHANGES_PRICEGROUPS: parseToggle('enabled', 'TOGGLE_BULK_CHANGES_PRICEGROUPS', skipValidation),
  TOGGLE_MO_FROM_CHAT: parseToggle('enabled', 'TOGGLE_MO_FROM_CHAT', skipValidation),
  TOGGLE_MO_PRICES_INFO: parseToggle('enabled', 'TOGGLE_MO_PRICES_INFO', skipValidation),
  TOGGLE_PRICEGROUPS_CUSTOM_COLUMNS: parseToggle('enabled', 'TOGGLE_PRICEGROUPS_CUSTOM_COLUMNS', skipValidation),
  TOGGLE_UNIVERSITY: parseToggle('enabled', 'TOGGLE_UNIVERSITY', skipValidation),
  TOGGLE_MINI_CHAT: parseToggle('enabled', 'TOGGLE_MINI_CHAT', skipValidation),
  TOGGLE_SUPPLIER_FILTER: parseToggle('enabled', 'TOGGLE_SUPPLIER_FILTER', skipValidation),
  TOGGLE_ORDER_ITEM_ISSUE: parseToggle('enabled', 'TOGGLE_ORDER_ITEM_ISSUE', skipValidation),
  TOGGLE_CONSENTIO_PRODUCT_ID: parseToggle('enabled', 'TOGGLE_CONSENTIO_PRODUCT_ID', skipValidation),
  TOGGLE_CART_ICON: parseToggle('enabled', 'TOGGLE_CART_ICON', skipValidation),
  TOGGLE_REQUEST_ORDER: parseToggle('enabled', 'TOGGLE_REQUEST_ORDER', skipValidation),
  TOGGLE_NEW_CART_CARD: parseToggle('enabled', 'TOGGLE_NEW_CART_CARD', skipValidation),
  TOGGLE_CLIENT_CURRENCY: parseToggle('enabled', 'TOGGLE_CLIENT_CURRENCY', skipValidation),
  TOGGLE_PRODUCT_TOOLTIP: parseToggle('enabled', 'TOGGLE_PRODUCT_TOOLTIP', skipValidation),
  TOGGLE_BOXES_PER_PALLET: parseToggle('enabled', 'TOGGLE_BOXES_PER_PALLET', skipValidation),
  TOGGLE_LOAD_DATE: parseToggle('enabled', 'TOGGLE_LOAD_DATE', skipValidation),
  TOGGLE_DISCARD_MO: parseToggle('enabled', 'TOGGLE_DISCARD_MO', skipValidation),
  TOGGLE_ORDER_PRICE_UNITS: parseToggle('enabled', 'TOGGLE_ORDER_PRICE_UNITS', skipValidation),
  TOGGLE_MAPPINGS_PER_CLIENT: parseToggle('enabled', 'TOGGLE_MAPPINGS_PER_CLIENT', skipValidation),
  TOGGLE_CRUD_REFERENCES: parseToggle('enabled', 'TOGGLE_CRUD_REFERENCES', skipValidation),
  TOGGLE_REPEAT_OFFER: parseToggle('enabled', 'TOGGLE_REPEAT_OFFER', skipValidation),

  // Disabled toggles, can be activated per catalog via database
  TOGGLE_CANT_REPEAT_NOR_DRAFT_NOR_HISTORY: parseToggle(
    'disabled',
    'TOGGLE_CANT_REPEAT_NOR_DRAFT_NOR_HISTORY',
    skipValidation,
  ),
  TOGGLE_ATHINA: parseToggle('disabled', 'TOGGLE_ATHINA', skipValidation),
  TOGGLE_PUBLIC_CHAT: parseToggle('disabled', 'TOGGLE_PUBLIC_CHAT', skipValidation),
  TOGGLE_SUSTAINABILITY: parseToggle('disabled', 'TOGGLE_SUSTAINABILITY', skipValidation),
  TOGGLE_PRODUCT_LOT: parseToggle('disabled', 'TOGGLE_PRODUCT_LOT', skipValidation),
  TOGGLE_SUSTAINABILITY_HOVER: parseToggle('disabled', 'TOGGLE_SUSTAINABILITY_HOVER', skipValidation),
  TOGGLE_EDI_TABS: parseToggle('disabled', 'TOGGLE_EDI_TABS', skipValidation),
  TOGGLE_MARGINS: parseToggle('disabled', 'TOGGLE_MARGINS', skipValidation),
  TOGGLE_NO_WORKSPACE_MODAL: parseToggle('disabled', 'TOGGLE_NO_WORKSPACE_MODAL', skipValidation),
  TOGGLE_EDIFACT_CODE: parseToggle('disabled', 'TOGGLE_EDIFACT_CODE', skipValidation),
  TOGGLE_NEW_SALES_UNITS: parseToggle('org:25408, 6663', 'TOGGLE_NEW_SALES_UNITS', skipValidation),
  TOGGLE_PRICE_MISMATCH: parseToggle('disabled', 'TOGGLE_PRICE_MISMATCH', skipValidation),
  TOGGLE_PRODUCTS_BUYER_REFERENCES: parseToggle('disabled', 'TOGGLE_PRODUCTS_BUYER_REFERENCES', skipValidation),
  TOGGLE_BUYER_OFFER_TABLE: parseToggle('disabled', 'TOGGLE_BUYER_OFFER_TABLE', skipValidation),
  TOGGLE_SELLER_OFFER_TABLE: parseToggle('disabled', 'TOGGLE_SELLER_OFFER_TABLE', skipValidation),
  TOGGLE_RANK_PRICEGROUPS: parseToggle('disabled', 'TOGGLE_RANK_PRICEGROUPS', skipValidation),
};

// Environment overrides for toggles for different environments
const environmentOverrides: { [key: string]: Partial<Config> } = {
  dev: {
    // if you want to enable a toggle for your local machine without going through database, you can do it here
    // TOGGLE_NEW_SALES_UNITS: parseToggle('enabled', 'TOGGLE_NEW_SALES_UNITS', skipValidation),
    TOGGLE_CRUD_REFERENCES: parseToggle('enabled', 'TOGGLE_CRUD_REFERENCES', skipValidation),
    TOGGLE_RANK_PRICEGROUPS: parseToggle('enabled', 'TOGGLE_RANK_PRICEGROUPS', skipValidation),
    TOGGLE_SELLER_OFFER_TABLE: parseToggle('enabled', 'TOGGLE_SELLER_OFFER_TABLE', skipValidation),
    TOGGLE_PRODUCTS_BUYER_REFERENCES: parseToggle('enabled', 'TOGGLE_PRODUCTS_BUYER_REFERENCES', skipValidation),
    TOGGLE_BUYER_OFFER_TABLE: parseToggle('enabled', 'TOGGLE_BUYER_OFFER_TABLE', skipValidation),
  },
  alpha: {
    TOGGLE_PRODUCTS_BUYER_REFERENCES: parseToggle('enabled', 'TOGGLE_PRODUCTS_BUYER_REFERENCES', skipValidation),
    TOGGLE_ATHINA: parseToggle('enabled', 'TOGGLE_ATHINA', skipValidation),
    TOGGLE_PUBLIC_CHAT: parseToggle('enabled', 'TOGGLE_PUBLIC_CHAT', skipValidation),
    TOGGLE_SUSTAINABILITY: parseToggle('enabled', 'TOGGLE_SUSTAINABILITY', skipValidation),
    TOGGLE_PRODUCT_LOT: parseToggle('enabled', 'TOGGLE_PRODUCT_LOT', skipValidation),
    TOGGLE_SUSTAINABILITY_HOVER: parseToggle('enabled', 'TOGGLE_SUSTAINABILITY_HOVER', skipValidation),
    TOGGLE_EDI_TABS: parseToggle('enabled', 'TOGGLE_EDI_TABS', skipValidation),
    TOGGLE_MARGINS: parseToggle('enabled', 'TOGGLE_MARGINS', skipValidation),
    TOGGLE_NO_WORKSPACE_MODAL: parseToggle('enabled', 'TOGGLE_NO_WORKSPACE_MODAL', skipValidation),
    TOGGLE_EDIFACT_CODE: parseToggle('enabled', 'TOGGLE_EDIFACT_CODE', skipValidation),
    TOGGLE_BUYER_OFFER_TABLE: parseToggle('enabled', 'TOGGLE_BUYER_OFFER_TABLE', skipValidation),
    TOGGLE_SELLER_OFFER_TABLE: parseToggle('enabled', 'TOGGLE_SELLER_OFFER_TABLE', skipValidation),
  },
  beta: {
    TOGGLE_ATHINA: parseToggle('enabled', 'TOGGLE_ATHINA', skipValidation),
    TOGGLE_PUBLIC_CHAT: parseToggle('enabled', 'TOGGLE_PUBLIC_CHAT', skipValidation),
    TOGGLE_SUSTAINABILITY: parseToggle('enabled', 'TOGGLE_SUSTAINABILITY', skipValidation),
    TOGGLE_PRODUCT_LOT: parseToggle('enabled', 'TOGGLE_PRODUCT_LOT', skipValidation),
    TOGGLE_SUSTAINABILITY_HOVER: parseToggle('enabled', 'TOGGLE_SUSTAINABILITY_HOVER', skipValidation),
    TOGGLE_EDI_TABS: parseToggle('enabled', 'TOGGLE_EDI_TABS', skipValidation),
    TOGGLE_MARGINS: parseToggle('enabled', 'TOGGLE_MARGINS', skipValidation),
    TOGGLE_NO_WORKSPACE_MODAL: parseToggle('enabled', 'TOGGLE_NO_WORKSPACE_MODAL', skipValidation),
    TOGGLE_EDIFACT_CODE: parseToggle('enabled', 'TOGGLE_EDIFACT_CODE', skipValidation),
  },
};

// Config for all environments
const config: Config = {
  ...global._env_,
  REVISION: getEnvironments().REACT_APP_GIT_SHA,
  ...{ ...commonConfig, ...(environmentOverrides[ENV.APP_ENV] || {}) }, // merge common and environment specific config
};

export default config as Config;
