import {
  __,
  buyerWorkspaceSelectors,
  IProductKind,
  IReferenceResponse,
  productKindActions,
  sellerWorkspaceService,
  userSelectors,
  utils,
} from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ROUTE_PATHS } from '../../../constants';
import { navSelectors } from '../../../selectors';
import getPath from '../../../util/routes';
import MiniImages from '../MiniImages';
import Table, { IColumn } from '../Table/Table.component';
import * as S from './ReferenceTable.styled';

export interface IProps {
  references?: Array<IReferenceResponse>;
  workspaceId: string;
  onLoadMore?: () => void;
}

const ReferenceTable: React.FC<IProps> = ({ references, workspaceId, onLoadMore }) => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  const [productKinds, setProductKinds] = React.useState<Array<IProductKind>>([]);
  const [amEditor, setAmEditor] = React.useState<boolean>(false);
  const workspaceSelected = useSelector(navSelectors.getSelectedWorkspace);
  const me = useSelector(userSelectors.getUser);

  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (workspaceId) {
      dispatch(
        productKindActions.productKindsGet(res => {
          setProductKinds(res);
        }),
      );
      setAmEditor(sellerWorkspaceService.getRole(workspaceSelected, me.id) !== 'viewer');
    }
  }, []);

  const columns = [
    { value: 'name', label: 'name' },
    { value: 'type', label: 'type' },
    { value: 'code', label: 'code' },
    { value: 'sellers', label: 'sellers' },
  ];
  const getColumns = (): Array<IColumn> => {
    return [
      {
        title: __('Components.Referential.table.name'),
        id: 'name',
        width: '120px',
        element: (data: IReferenceResponse) => {
          return <S.NameText>{data.name}</S.NameText>;
        },
      },
      {
        title: __('Components.Referential.table.type'),
        id: 'type',
        width: '100px',
        element: (data: IReferenceResponse) => {
          // @ts-ignore
          const productKind = utils.translateProductKind(productKinds.find(p => p.id === data.productKindId)?.name);
          return <S.TextBlack>{productKind}</S.TextBlack>;
        },
      },
      {
        title: __('Components.Referential.table.code'),
        id: 'code',
        width: '100px',
        element: (data: IReferenceResponse) => {
          return <S.TextBlack>{data.description}</S.TextBlack>;
        },
      },
      {
        title: __('Components.Referential.table.sellers'),
        id: 'sellers',
        width: '80px',
        element: (data: IReferenceResponse) => {
          const workspaceIdNumber = Number(workspaceId);
          const supplierIds = data.suppliers.map(s => Number(s.id));
          const suppliers = supplierIds.map(supplierId =>
            useSelector<any, ISupplier | null>(state =>
              buyerWorkspaceSelectors.getSupplier(workspaceIdNumber, supplierId)(state),
            ),
          );
          const images = data.suppliers.map(s => s.workspace.pictureUrl);
          const threeImages = images.slice(0, 3);
          return images.length > 0 ? (
            <MiniImages
              className="reference-table"
              images={threeImages}
              onImageClick={() => null}
              count={suppliers.length}
            />
          ) : (
            <S.ZeroCaseImg>{'-'}</S.ZeroCaseImg>
          );
        },
      },
    ];
  };

  // Scroll columns
  React.useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

      if (scrollHeight - scrollTop <= clientHeight + 1) {
        onLoadMore();
      }
    };

    const container = containerRef.current;
    container?.addEventListener('scroll', handleScroll);

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, [onLoadMore]);
  
  return (
    <S.Container ref={containerRef}>
      <Table
        selectable={false}
        columns={getColumns()}
        emptyText=""
        onClickRow={ref => {
          history.push(
            getPath({
              path: ROUTE_PATHS.WORKSPACE_REFERENCE_SCREEN,
              workspaceId,
              referentialId: ref?.id,
            }),
          );
        }}
        values={references}
        productColumns={columns}
        showStickyHeader={true}
        sort="updatedAt"
        sortOrder="desc"
      />
    </S.Container>
  );
};

export default ReferenceTable;
