import {
  Api,
  buyerWorkspaceActions,
  modalActions,
  notificationsActions,
  orderActions,
  sellerWorkspaceActions,
  userActions,
} from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import OrdersDashboard, { IDispatchProps, IRouteProps, IStateProps } from '../components/organisms/OrdersDashboard';
import { IReduxState } from '../reducers';

const emptyObject = {};

function mapStateToProps(state: IReduxState, props: IRouteProps): IStateProps {
  const {
    catalog: { catalogs },
    order: { orders, searchId, totalResults, unreads },
    user: { user },
    workspace: { suppliers, workspaces },
  } = state;

  const orderFiltersState = state.order.filters || emptyObject;
  return {
    amSeller: state.user.user.settings.isSeller,
    catalogs: catalogs || emptyObject,
    contacts: state.contact.inConsentio,
    filters: orderFiltersState[0] || emptyObject,
    me: user!,
    orders,
    orderUnreads: unreads[0],
    searchId,
    suppliers,
    totalResults,
    workspaces,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      orderFiltersSet: orderActions.orderFiltersSet,
      orderGetByHash: orderActions.orderGetByHash,
      orderScrollSearch: orderActions.orderScrollSearch,
      ordersDashboardGet: orderActions.ordersDashboardGet,
      orderSearch: orderActions.orderSearch,
      ordersAllMarkAsRead: orderActions.ordersAllMarkAsRead,
      orderToggleReadStatus: orderActions.toggleOrderReadStatus,
      touchImage: modalActions.touchImage,
      modalOpen: modalActions.modalOpen,
      modalClose: modalActions.modalClose,
      notificationShow: notificationsActions.notificationShow,
      requestProInfo: userActions.requestProInfo,
      suppliersGet: buyerWorkspaceActions.suppliersGet,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersDashboard);
