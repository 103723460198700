import {
  __,
  BUSINESS_TYPE,
  constants,
  countryActions,
  date,
  imageActions,
  LOCALE,
  ModalActions,
  modalActions,
  NOTIFICATION_METHOD,
  NOTIFICATION_TOPIC,
  notificationsActions,
  prodTypeActions,
  RenderTrack,
  RETURNING_IN,
  sellerWorkspaceService,
  userActions,
  utils,
  validations,
  WORKING_STATUS,
} from 'common-services';
import { AsYouType } from 'libphonenumber-js/mobile';
import * as React from 'react';
import { setDefaultLocale } from 'react-datepicker';
import { Prompt, RouteComponentProps } from 'react-router-dom';

import { getLanguageLiteral, getReturnToAvailableOptions } from '../../../constants';
import Layout from '../../../layout-flex';
import TwoFAModal from '../../../screens/2fa-modal';
import { convertToIFile } from '../../../services/file';
import { resizeImage } from '../../../services/image';
import { logError } from '../../../services/log';
import { deviceIsIpad } from '../../../util/utils';
import { ColumnContainer, FontIcon, Picture, Radio, Select, TextArea } from '../../atoms';
import { FormContainer, FormMenu, FormSection, InputWithLabel, MemberSelector, MembersUpdate } from '../../molecules';
import { highlightFormSection, Sections } from '../../molecules/FormMenu/FormMenu.component';
import * as S from './Settings.styled';
import { getBusinessTypeInfo } from './Settings.utils';

declare var global: IGlobalWeb;

type IOwnProps = RouteComponentProps<{ tab?: string }>;

export interface IStateProps {
  catalog: IWorkspace;
  contacts: { [cId: number]: IContact };
  editMode: boolean;
  me: IUser;
  tags: Array<string>;
}

export interface IDispatchProps {
  countriesGet: typeof countryActions.countriesGet;
  mediaUpload: typeof imageActions.mediaUploadWithProgress;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  prodTypesGet: typeof prodTypeActions.prodTypesGet;
  sendEmailVerification: typeof userActions.sendEmailVerification;
  notificationShow: typeof notificationsActions.notificationShow;
  updateUser: typeof userActions.updateUser;
  verifyUser: typeof userActions.verifyUserData;
}

type IProps = IStateProps & IDispatchProps & IOwnProps;

interface IState {
  activate2FA: boolean;
  errors: Map<string, string | (() => JSX.Element)>;
  hasChanges: boolean;
  newMe: IUser;
  show2FAModal: boolean;
  viewing?: string;
}

export default class Settings extends React.PureComponent<IProps, IState> {
  private t: number;
  private sectionsRef: React.RefObject<HTMLDivElement> = React.createRef();
  private profileRef: React.RefObject<HTMLDivElement> = React.createRef();
  private configRef: React.RefObject<HTMLDivElement> = React.createRef();
  private notifsRef: React.RefObject<HTMLDivElement> = React.createRef();
  private securityRef: React.RefObject<HTMLDivElement> = React.createRef();
  private workingStatusRef: React.RefObject<HTMLDivElement> = React.createRef();
  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    this.state = {
      activate2FA: false,
      errors: new Map(),
      hasChanges: false,
      newMe: props.me,
      show2FAModal: false,
      viewing: props.match.params?.tab || 'profile',
    };
  }

  public componentDidMount() {
    RenderTrack.track('Settings', { renderTime: this.t });
    const offset = 200;
    if (this.sectionsRef.current) {
      this.sectionsRef.current.addEventListener('scroll', () => {
        if (this.sectionsRef.current.scrollTop + offset >= this.securityRef?.current?.offsetTop) {
          this.setState({ viewing: 'security' });
        } else if (this.sectionsRef.current?.scrollTop + offset >= this.notifsRef?.current?.offsetTop) {
          this.setState({ viewing: 'notifications' });
        } else if (this.sectionsRef.current?.scrollTop + offset >= this.configRef?.current?.offsetTop) {
          this.setState({ viewing: 'config' });
        } else if (this.sectionsRef.current?.scrollTop + offset >= this.workingStatusRef?.current?.offsetTop) {
          this.setState({ viewing: 'working-status' });
        } else if (this.sectionsRef.current?.scrollTop + offset >= this.profileRef?.current?.offsetTop) {
          this.setState({ viewing: 'profile' });
        }
      });

      const { viewing } = this.state;
      if (viewing !== 'profile') {
        const el = document.getElementById(viewing);
        if (el) el.scrollIntoView({ behavior: 'smooth' });
        highlightFormSection(viewing);
      }
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const { match, me } = this.props;
    const { viewing } = this.state;
    if (prevProps.me !== me) this.setState({ newMe: { ...me } });
    // If URL changes, we scroll to the correct section
    if (match !== prevProps.match) {
      const newViewing = match.params.tab || 'profile';
      if (newViewing !== viewing) {
        this.setState({ viewing: newViewing });
        const el = document.getElementById(newViewing);
        if (el) el.scrollIntoView({ behavior: 'smooth' });
        highlightFormSection(newViewing);
      }
    }
  }

  public render() {
    const {
      catalog,
      contacts,
      editMode,
      mediaUpload,
      me,
      modalClose,
      modalOpen,
      sendEmailVerification,
      history,
      match,
      location,
    } = this.props;
    const { activate2FA, newMe, errors, hasChanges, viewing, show2FAModal } = this.state;

    const sections: Sections = [
      {
        id: 'profile',
        title: __('Components.Settings.Menu.profile.title'),
        description: __('Components.Settings.Menu.profile.description'),
        icon: 'Profile',
      },
      {
        id: 'working-status',
        title: __('Components.Settings.working_status'),
        description: __('Components.Settings.working_status_subtitle'),
        icon: 'Availability',
      },
      {
        id: 'config',
        title: __('Components.Settings.Menu.config.title'),
        description: __('Components.Settings.Menu.config.description'),
        icon: 'Settings',
      },
      {
        id: 'notifications',
        title: __('Components.Settings.Menu.notifications.title'),
        description: __('Components.Settings.Menu.notifications.description'),
        icon: 'Notification',
      },
    ];
    sections.push({
      id: 'security',
      title: __('Components.Settings.Menu.security.title'),
      description: __('Components.Settings.Menu.security.description'),
      icon: 'Security',
    });

    return (
      <Layout
        header={{
          show: true,
          title: __('Components.Header.Config'),
          subtitle: this.getSubtitle(),
          tabSelected: 'settings',
          breadcrumbs: [{ label: __('Components.Header.Config') }, { label: this.getSubtitle() }],
        }}
      >
        <S.Container>
          <Prompt when={hasChanges} message={__('Components.ProductDetails.confirm_exit_changes')} />
          <FormMenu sections={sections} selected={viewing} />
          <FormContainer
            canSave={hasChanges && !errors.size}
            save={this.onCTAClick}
            className="UserInfo-FormContainer"
            sectionsRef={this.sectionsRef}
          >
            <Profile
              me={newMe}
              errors={errors}
              setError={this.setError}
              updateMe={this.updateMe}
              editMode={editMode}
              modalOpen={modalOpen}
              modalClose={modalClose}
              mediaUpload={mediaUpload}
              profileRef={this.profileRef}
              sendEmailVerification={sendEmailVerification}
            />
            <WorkingStatus
              catalog={catalog}
              contacts={contacts}
              editMode={editMode}
              errors={errors}
              me={newMe}
              setError={this.setError}
              updateMe={this.updateMe}
              workingStatusRef={this.workingStatusRef}
            />
            <Config
              me={newMe}
              errors={errors}
              setError={this.setError}
              updateMe={this.updateMe}
              editMode={editMode}
              configRef={this.configRef}
            />
            <Notifications me={newMe} updateMe={this.updateMe} editMode={editMode} notificationsRef={this.notifsRef} />
            <Security
              me={newMe}
              securityRef={this.securityRef}
              show2FAModal={(activate: boolean) => this.setState({ show2FAModal: true, activate2FA: activate })}
            />
          </FormContainer>
          {show2FAModal ? (
            <TwoFAModalComponent
              toActivate={activate2FA}
              onClose={(toActive?: boolean) => {
                this.setState({ show2FAModal: false });
                if (toActive === undefined) {
                  // Hack to reset the switch when closing without having taking any action
                  this.setState(
                    {
                      newMe: { ...newMe, settings: { ...newMe.settings, twoFAEnabled: !me.settings.twoFAEnabled } },
                    },
                    () => {
                      this.setState({
                        newMe: { ...newMe, settings: { ...newMe.settings, twoFAEnabled: me.settings.twoFAEnabled } },
                      });
                    },
                  );
                }
              }}
            />
          ) : null}
        </S.Container>
      </Layout>
    );
  }

  /**
   * Get subtitle according to tab
   */
  private getSubtitle = () => {
    return __('Components.Header.My') + ' ' + __('Components.Header.Profile');
  };

  /**
   * On CTA click
   */
  private onCTAClick = () => {
    const { verifyUser } = this.props;
    const { newMe } = this.state;
    const userPhone = newMe.country && newMe.phone ? newMe.country + '' + newMe.phone : '';
    if (userPhone)
      verifyUser(userPhone, newMe.email, (code: string) => {
        if (code === 'email-taken') {
          return this.setError('email', () => (
            <React.Fragment>
              {__('Components.Onboarding.error_email_already_taken_1')}
              <S.LinkSupport href="mailto:support@consentio.co" target="_blank" rel="noopener noreferrer">
                {__('Components.Onboarding.error_email_already_taken_link')}
              </S.LinkSupport>
              {__('Components.Onboarding.error_email_already_taken_2')}
            </React.Fragment>
          ));
        }
        this.updateUser();
      });
    else this.updateUser();
  };

  /**
   * Update user and show notification
   */
  private updateUser = () => {
    const { updateUser, countriesGet, prodTypesGet, notificationShow, me } = this.props;
    const { newMe } = this.state;

    if (me.settings.businessType !== newMe.settings.businessType) {
      global.localStorage.setItem('homeTeamsSeen', 'false');
    }

    updateUser(newMe, (error?: Error) => {
      if (error) {
        return notificationShow({
          title: __('Components.Settings.error', { error }),
          subtitle: '',
          closable: true,
          style: 'error',
        });
      }
      this.setState({ hasChanges: false });

      const l = newMe.settings.language.substring(0, 2) as LOCALE;
      global.localStorage.setItem('language', l);
      global.localStorage.setItem('username', newMe.name);
      countriesGet(l);
      prodTypesGet(l);
      setDefaultLocale(l);
      return notificationShow({
        title: __('Components.Settings.saved'),
        subtitle: __('Components.Settings.saved_subtitle'),
        closable: true,
        style: 'success',
      });
    });
  };

  /**
   * Set error for a particular field represented by key
   */
  private setError = (key: string, error: string | (() => JSX.Element)) => {
    const { errors } = this.state;
    const cloneErrors = new Map(errors);
    const keyWithPrefix = key;
    if (error) {
      cloneErrors.set(keyWithPrefix, error);
    } else {
      cloneErrors.delete(keyWithPrefix);
    }
    this.setState({ errors: cloneErrors });
  };

  /**
   * Change the local state of user with the changes in the form.
   */
  private updateMe = (changes: Array<{ n: string; v: any }>) => {
    const { newMe } = this.state;
    this.setState({ hasChanges: true });
    this.setState({
      newMe: changes.reduce(
        (acc, e) => {
          acc[e.n] = e.v;
          return acc;
        },
        { ...newMe },
      ),
    });
  };
}

const Profile = ({
  me,
  errors,
  setError,
  updateMe,
  editMode,
  modalOpen,
  modalClose,
  mediaUpload,
  profileRef,
  sendEmailVerification,
}: {
  errors: Map<string, string | (() => JSX.Element)>;
  editMode: boolean;
  me: IUser;
  setError: (key: string, error: string | (() => JSX.Element)) => void;
  updateMe: (changes: Array<{ n: string; v: any }>) => void;
  mediaUpload: (image: IFile, cb: (data: any) => void) => void;
  modalClose: () => ModalActions;
  modalOpen: (text: string, action: any, extra?: IModalExtraInfo) => ModalActions;
  profileRef: React.RefObject<HTMLDivElement>;
  sendEmailVerification: (myId: number, cb?: (err?: Error) => void) => void;
}) => {
  const [emailVerificationSent, setEmailVerificationSent] = React.useState(false);
  const {
    name,
    companyName,
    email,
    emailValidated,
    country,
    phone,
    settings: { avatar, businessType, hidePhone },
  } = me;
  const emailError = errors.get('email');
  const hasPhone = !!(country && phone);
  return (
    <FormSection id="profile" title={__('Components.Settings.profile')} sectionRef={profileRef}>
      <S.RowForm>
        <S.ImageWrapper>
          <Picture
            editable={editMode}
            height="136px"
            imageUrl={avatar}
            margin="5px 0"
            onDelete={() => onPicturedDelete(0)}
            onFileChange={file => onPictureChange(file)}
            pictureMode={true}
            picturePlaceholder={__('Components.Picture.placeholder.avatar')}
            relation={constants.IMAGE_RELATION.AVATAR}
            size="big"
            width="136px"
            withCrop={deviceIsIpad() ? false : true}
          />
        </S.ImageWrapper>
        <ColumnContainer>
          <InputWithLabel isRequired={true} label={__('Components.Settings.name')}>
            <S.Input
              name="name"
              placeholder={__('Components.Settings.name_placeholder')}
              value={name}
              trim={true}
              isRequired={true}
              onChange={(n, v) => {
                setError(n, v ? '' : 'empty');
                updateMe([{ n, v }]);
              }}
              onBlur={(n, value) => {
                setError(n, value ? '' : 'empty');
              }}
              disabled={!editMode}
              hasError={!!errors.get('name')}
              width="350px"
            />
          </InputWithLabel>
          {businessType !== BUSINESS_TYPE.CONSUMER ? (
            <InputWithLabel isRequired={true} label={__('Components.Settings.company')}>
              <S.Input
                name="companyName"
                placeholder={__('Components.Settings.company_placeholder')}
                value={companyName}
                trim={true}
                isRequired={true}
                onChange={(n, v) => {
                  setError(n, v ? '' : 'empty');
                  updateMe([{ n, v }]);
                }}
                onBlur={(n, value) => {
                  setError(n, value ? '' : 'empty');
                }}
                disabled={!editMode}
                hasError={!!errors.get('companyName')}
                width="350px"
              />
            </InputWithLabel>
          ) : null}
        </ColumnContainer>
      </S.RowForm>
      <InputWithLabel
        isRequired={true}
        label={__('Components.Settings.email')}
        disabled={emailValidated}
        isVerified={emailValidated}
      >
        <S.Input
          name="email"
          placeholder={__('Components.Settings.email_placeholder')}
          containerMargin={'0'}
          value={email}
          isRequired={true}
          onChange={(n, v) => {
            setError(n, v ? '' : 'empty');
            updateMe([{ n, v }]);
          }}
          onBlur={(n, value) => {
            setError(n, value ? '' : 'empty');
            if (value && !validations.validateEmail(value.toString())) {
              setError(n, 'validation');
            }
          }}
          disabled={!editMode || emailValidated}
          hasError={!!errors.get('email')}
          width="494px"
        />
      </InputWithLabel>
      {emailError ? (
        <S.TextError>{typeof emailError === 'string' ? getEmailErrorLiteral() : emailError()}</S.TextError>
      ) : null}
      {emailValidated ? null : (
        <S.ResendEmailContainer>
          <S.WarningIconWrapper>
            <FontIcon name="Warning" disableHover={true} />
          </S.WarningIconWrapper>
          <S.Text>
            {__('Components.Settings.email_not_verified_1')}
            <S.TextLink onClick={onSendEmailClick}>{__('Components.Settings.email_not_verified_link')}</S.TextLink>
          </S.Text>
        </S.ResendEmailContainer>
      )}
      {hasPhone ? (
        <InputWithLabel
          isRequired={true}
          label={__('Components.Settings.phoneNumber')}
          disabled={true}
          isVerified={true}
          footerText={utils.formatText(
            hidePhone ? __('Components.Settings.hidePhone.info') : __('Components.Settings.showPhone.info'),
            (s, i) => (
              <S.TextLink
                key={s + i}
                onClick={() => {
                  const el = document.getElementById('hidePhone');
                  if (el) {
                    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  }
                }}
              >
                {s}
              </S.TextLink>
            ),
          )}
        >
          <S.Input
            name="phoneNumber"
            value={new AsYouType().input('+' + country + '' + phone)}
            isRequired={true}
            disabled={true}
            width="150px"
          />
        </InputWithLabel>
      ) : null}
    </FormSection>
  );
  /**
   * On picture change:
   * * Upload picture
   * * Update in product state
   */
  async function onPictureChange(f: File) {
    convertToIFile(
      f,
      (file: IFile, fileString: string) => {
        if (fileString)
          resizeImage(fileString, (imageResized: string) =>
            mediaUpload({ ...file, content: imageResized }, result =>
              updateMe([{ n: 'settings', v: { ...me.settings, avatar: result ? result.secure_url : undefined } }]),
            ),
          );
      },
      (code: string) => {
        logError(new Error('File could not be read! Code ' + code), 'upload.image');
      },
    );
  }

  /**
   * On delete picture with confirmation modal
   */
  function onPicturedDelete(index: number): void {
    modalOpen(
      __('Components.ProductDetails.confirm_remove_picture'),
      () => {
        updateMe([{ n: 'settings', v: { ...me.settings, avatar: undefined } }]);
        modalClose();
      },
      {
        buttonText: __('Components.Modal.delete'),
      },
    );
  }

  /**
   * Get email error literal explanation
   */
  function getEmailErrorLiteral() {
    if (emailError === 'empty') {
      return __('Components.Settings.error_email_empty');
    } else {
      return __('Components.Settings.error_email_valid');
    }
  }

  /**
   * Send a new email verification.
   * If already sent, just show an information modal.
   */
  function onSendEmailClick() {
    if (emailVerificationSent) {
      return modalOpen(__('Components.Settings.email_verification_already_sent', { email }), modalClose);
    }
    sendEmailVerification(me.id, err => {
      if (!err) {
        setEmailVerificationSent(true);
        modalOpen(__('Components.Settings.email_verification_sent', { email }), modalClose);
      }
    });
  }
};

const Config = ({
  me,
  errors,
  setError,
  updateMe,
  configRef,
}: {
  errors: Map<string, string | (() => JSX.Element)>;
  editMode: boolean;
  me: IUser;
  setError: (key: string, error: string | (() => JSX.Element)) => void;
  updateMe: (changes: Array<{ n: string; v: any }>) => void;
  configRef: React.RefObject<HTMLDivElement>;
}) => {
  const {
    settings: { language, timezone, isBuyer, isSeller, businessType, dateFormat, hidePhone, hourFormat },
  } = me;
  const businessInfo = getBusinessTypeInfo(businessType, isBuyer, isSeller);
  return (
    <FormSection id="config" title={__('Components.Settings.config')} sectionRef={configRef}>
      <InputWithLabel isRequired={true} label={businessInfo.title} description={businessInfo.description}>
        <Select
          name="businessType"
          value={businessType}
          options={[
            BUSINESS_TYPE.GROWER,
            BUSINESS_TYPE.WHOLESALER,
            BUSINESS_TYPE.SUPERMARKET,
            BUSINESS_TYPE.POINT_OF_SALE,
            BUSINESS_TYPE.CONSUMER,
          ].map(bt => ({
            value: bt,
            label: constants.businessTypeLiterals(bt).title,
          }))}
          onChange={(n, v) => {
            setError(n, v ? '' : 'empty');
            updateMe([{ n: 'settings', v: { ...me.settings, businessType: v } }]);
          }}
          containerMargin="4px 0"
          width="350px"
          hasError={!!errors.get('businessType')}
        />
      </InputWithLabel>
      <InputWithLabel isRequired={true} label={__('Components.Settings.language.label')}>
        <Select
          name="language"
          value={language}
          options={Object.values(LOCALE).map(l => ({
            value: l,
            label: getLanguageLiteral(l),
          }))}
          onChange={(n, v) => {
            setError(n, v ? '' : 'empty');
            updateMe([{ n: 'settings', v: { ...me.settings, language: v } }]);
          }}
          containerMargin="4px 0"
          width="350px"
          hasError={!!errors.get('language')}
        />
      </InputWithLabel>
      <InputWithLabel
        isRequired={true}
        label={__('Components.Settings.timezone.label')}
        description={__('Components.Settings.timezone.description')}
      >
        <Select
          name="timezone"
          value={timezone}
          options={constants.TIMEZONES.map(l => ({ value: l, label: l }))}
          onChange={(n, v) => {
            setError(n, v ? '' : 'empty');
            updateMe([{ n: 'settings', v: { ...me.settings, timezone: v } }]);
          }}
          containerMargin="4px 0"
          width="350px"
          hasError={!!errors.get('timezone')}
        />
      </InputWithLabel>

      <InputWithLabel
        isRequired={true}
        label={__('Components.Settings.dateFormat.label')}
        description={__('Components.Settings.dateFormat.description')}
        footerText={__('Components.Settings.dateFormat.footer', {
          date: date.formatDate(Date.now(), dateFormat === 'dd/mm/yyyy' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'),
        })}
      >
        <Select
          name="dateFormat"
          value={dateFormat}
          options={Object.keys(constants.DATE_FORMATS).map(df => ({
            value: df,
            label: constants.DATE_FORMATS[df].name(),
          }))}
          onChange={(n, v) => {
            setError(n, v ? '' : 'empty');
            updateMe([{ n: 'settings', v: { ...me.settings, dateFormat: v } }]);
          }}
          containerMargin="4px 0"
          width="350px"
          hasError={!!errors.get('dateFormat')}
        />
      </InputWithLabel>

      <InputWithLabel isRequired={true} label={__('Components.Settings.hourFormat.label')} disabled={false}>
        <Radio
          name={'hourFormat'}
          direction={'column'}
          options={[
            {
              label: __('Components.Settings.hourFormat.H24'),
              value: 'H24',
            },
            { label: __('Components.Settings.hourFormat.H12'), value: 'H12' },
          ]}
          containerMargin={'4px 0'}
          alignItems={'flex-start'}
          styleLabel={{ margin: '5px 0' }}
          itemsChecked={[hourFormat]}
          onChange={(n, v) => {
            setError(n, v ? '' : 'empty');
            updateMe([{ n: 'settings', v: { ...me.settings, hourFormat: v } }]);
          }}
          disabled={false}
        />
      </InputWithLabel>
      <InputWithLabel
        id="hidePhone"
        isRequired={true}
        footerText={
          hidePhone ? __('Components.Settings.hidePhone.description') : __('Components.Settings.showPhone.description')
        }
      >
        <S.DelegateRow>
          <S.SectionTitle>
            {hidePhone ? __('Components.Settings.hidePhone.switch') : __('Components.Settings.showPhone.switch')}
          </S.SectionTitle>
          <S.SectionSwitch
            isChecked={!hidePhone}
            onChange={(_, value) => {
              updateMe([
                {
                  n: 'settings',
                  v: {
                    ...me.settings,
                    hidePhone: !value,
                  },
                },
              ]);
            }}
          />
        </S.DelegateRow>
      </InputWithLabel>
    </FormSection>
  );
};

const WorkingStatus = ({
  catalog,
  contacts,
  errors,
  me,
  setError,
  updateMe,
  workingStatusRef,
}: {
  catalog: IWorkspace;
  contacts: { [cId: number]: IContact };
  editMode: boolean;
  errors: Map<string, string | (() => JSX.Element)>;
  me: IUser;
  setError: (key: string, error: string | (() => JSX.Element)) => void;
  updateMe: (changes: Array<{ n: string; v: any }>) => void;
  workingStatusRef: React.RefObject<HTMLDivElement>;
}) => {
  const {
    settings: {
      dateFormat,
      hourFormat,
      delegates,
      messageOnAway,
      messageOnNotAvailable,
      muteNotificationsWhileNotAvailable,
      returnToAvailableOption,
      returnToAvailableTime,
      workingStatus,
    },
  } = me;
  const workingStatusLiterals = constants.workingStatusLiterals(workingStatus, me.name);
  const messageInputName = workingStatus === WORKING_STATUS.AWAY ? 'messageOnAway' : 'messageOnNotAvailable';
  const dateNow = new Date(Date.now());
  const returnToAvailableDate = returnToAvailableTime ? new Date(returnToAvailableTime) : dateNow;
  const delegateMemberId = delegates[catalog?.id];
  const delegateMember = delegateMemberId ? contacts[delegateMemberId] : undefined;
  return (
    <FormSection id="working-status" title={__('Components.Settings.working_status')} sectionRef={workingStatusRef}>
      <InputWithLabel isRequired={true} footerText={workingStatusLiterals.description}>
        <S.SelectWorkingStatus
          name="workingStatus"
          value={workingStatus || WORKING_STATUS.AVAILABLE}
          onChange={(n, v) => {
            setError(n, v ? '' : 'empty');
            updateMe([
              {
                n: 'settings',
                v: {
                  ...me.settings,
                  workingStatus: v,
                  returnToAvailableOption: RETURNING_IN.UNSPECIFIED,
                  returnToAvailableTime: undefined,
                },
              },
            ]);
          }}
          hasError={!!errors.get('workingStatus')}
        />
      </InputWithLabel>
      {[WORKING_STATUS.AWAY, WORKING_STATUS.NOT_AVAILABLE].includes(workingStatus) ? (
        <>
          <InputWithLabel
            isRequired={true}
            label={__('Components.Settings.return_availability')}
            footerText={
              returnToAvailableOption === RETURNING_IN.UNSPECIFIED
                ? __('Components.Settings.return_availability_description')
                : date.getReturningInDateLiteral(returnToAvailableDate, dateFormat, hourFormat)
            }
          >
            <Select
              name="returnToAvailable"
              value={returnToAvailableOption}
              options={getReturnToAvailableOptions()}
              onChange={(n, v) => {
                const returnToADate = date.getDateByReturningIn(v as RETURNING_IN);
                updateMe([
                  {
                    n: 'settings',
                    v: {
                      ...me.settings,
                      returnToAvailableOption: v,
                      returnToAvailableTime: returnToADate ? returnToADate.getTime() : undefined,
                    },
                  },
                ]);
              }}
              containerMargin="4px 0"
              width="350px"
              hasError={!!errors.get('returnToAvailable')}
            />
          </InputWithLabel>
          {returnToAvailableOption === RETURNING_IN.CUSTOM ? (
            <S.DatePicker
              clearable={false}
              dateFormat={dateFormat === 'dd/mm/yyyy' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
              id={`returning-in-date`}
              initDate={returnToAvailableDate || dateNow}
              minDate={dateNow}
              minTimeToday={dateNow}
              openDate={returnToAvailableDate || dateNow}
              onDateChange={newDate => {
                const dateToUpdate = new Date(newDate);
                updateMe([
                  {
                    n: 'settings',
                    v: { ...me.settings, returnToAvailableTime: dateToUpdate.getTime() },
                  },
                ]);
              }}
              showTimeSelect={true}
              timeIntervals={15}
            />
          ) : null}
        </>
      ) : null}
      {workingStatus === WORKING_STATUS.NOT_AVAILABLE ? (
        <S.CheckboxRow>
          <S.CheckBox
            className="mute-notifications-checkbox"
            isChecked={muteNotificationsWhileNotAvailable}
            onClick={() =>
              updateMe([
                {
                  n: 'settings',
                  v: { ...me.settings, muteNotificationsWhileNotAvailable: !muteNotificationsWhileNotAvailable },
                },
              ])
            }
          />
          <S.CheckBoxLabel
            onClick={() =>
              updateMe([
                {
                  n: 'settings',
                  v: { ...me.settings, muteNotificationsWhileNotAvailable: !muteNotificationsWhileNotAvailable },
                },
              ])
            }
          >
            {__('Components.Settings.mute_notifications')}
          </S.CheckBoxLabel>
        </S.CheckboxRow>
      ) : null}
      {[WORKING_STATUS.AWAY, WORKING_STATUS.NOT_AVAILABLE].includes(workingStatus) ? (
        <InputWithLabel isRequired={true} label={__('Components.Settings.notification_message')}>
          <TextArea
            name={messageInputName}
            value={workingStatus === WORKING_STATUS.AWAY ? messageOnAway : messageOnNotAvailable}
            onChange={(n, v) => {
              updateMe([{ n: 'settings', v: { ...me.settings, [messageInputName]: v } }]);
            }}
            containerMargin="4px 0"
            maxLength={140}
            rows={4}
            width="100%"
            placeholder={(workingStatusLiterals.public_message || '').replace(/\*\*/g, '')}
          />
        </InputWithLabel>
      ) : null}
      {workingStatus === WORKING_STATUS.NOT_AVAILABLE && catalog?.members.length > 1 ? (
        <InputWithLabel
          description={__('Components.Settings.delegate_description')}
          isRequired={true}
          label={__('Components.Settings.notifications_delegate')}
        >
          <S.DelegateContainer>
            <S.DelegateRow>
              <S.WorkspaceRow>
                <S.WorkspaceAvatar catalog={catalog} size={30} />
                <S.Text>{sellerWorkspaceService.getCatalogName(catalog, contacts, me)}</S.Text>
              </S.WorkspaceRow>
              <MembersUpdate
                className="settings-delegate-members-modal"
                contacts={contacts}
                disabled={false}
                disableSelectViewers={true}
                me={me}
                members={catalog.members
                  .filter(m => m.userId !== me.id)
                  .map(m => ({ ...m, disabled: m.role === 'viewer' }))}
                memberSelected={delegateMemberId}
                onSelect={(userId?: number) => {
                  const delegatesCopy = { ...delegates };
                  if (userId) {
                    delegatesCopy[catalog.id] = userId;
                  } else {
                    delete delegatesCopy[catalog.id];
                  }
                  updateMe([
                    {
                      n: 'settings',
                      v: { ...me.settings, delegates: delegatesCopy },
                    },
                  ]);
                }}
                role={sellerWorkspaceService.getRole(catalog, me.id)}
                showAuthor={false}
                title={__('Components.Settings.notifications_delegate')}
              >
                <MemberSelector member={delegateMember} />
              </MembersUpdate>
            </S.DelegateRow>
          </S.DelegateContainer>
        </InputWithLabel>
      ) : null}
    </FormSection>
  );
};

const Notifications = ({
  me,
  updateMe,
  notificationsRef,
}: {
  editMode: boolean;
  me: IUser;
  updateMe: (changes: Array<{ n: string; v: any }>) => void;
  notificationsRef: React.RefObject<HTMLDivElement>;
}) => {
  const { notifications, muteNotificationsWhileNotAvailable, workingStatus } = me.settings;

  return (
    <FormSection id="notifications" title={__('Components.Settings.notifications.title')} sectionRef={notificationsRef}>
      <S.Subtitle>{__('Components.Settings.notifications.subtitle')}</S.Subtitle>
      <S.Section>
        <S.SectionHeader>
          <S.SectionTitle>{__('Components.Settings.notifications.email')}</S.SectionTitle>
          <S.SectionSwitch
            isChecked={getNotificationValue(NOTIFICATION_METHOD.EMAIL, NOTIFICATION_TOPIC.ALL)}
            onChange={(_, value) => {
              updateMe([
                {
                  n: 'settings',
                  v: {
                    ...me.settings,
                    notifications: {
                      ...notifications,
                      [NOTIFICATION_METHOD.EMAIL]: {
                        ...(notifications[NOTIFICATION_METHOD.EMAIL] || {}),
                        [NOTIFICATION_TOPIC.ALL]: value,
                      },
                    },
                  },
                },
              ]);
            }}
          />
        </S.SectionHeader>
        {renderNotifOption(NOTIFICATION_METHOD.EMAIL, NOTIFICATION_TOPIC.ORDER_NEW)}
        {renderNotifOption(NOTIFICATION_METHOD.EMAIL, NOTIFICATION_TOPIC.ORDER_ACCEPTED)}
      </S.Section>

      <S.Section>
        <S.SectionHeader>
          <S.SectionTitle>{__('Components.Settings.notifications.push')}</S.SectionTitle>
          <S.SectionSwitch
            isChecked={getNotificationValue(NOTIFICATION_METHOD.PUSH, NOTIFICATION_TOPIC.ALL)}
            isDisabled={workingStatus === WORKING_STATUS.NOT_AVAILABLE && muteNotificationsWhileNotAvailable}
            onChange={(_, value) => {
              updateMe([
                {
                  n: 'settings',
                  v: {
                    ...me.settings,
                    notifications: {
                      ...notifications,
                      [NOTIFICATION_METHOD.PUSH]: {
                        ...(notifications[NOTIFICATION_METHOD.PUSH] || {}),
                        [NOTIFICATION_TOPIC.ALL]: value,
                      },
                    },
                  },
                },
              ]);
            }}
          />
        </S.SectionHeader>
        {renderNotifOption(NOTIFICATION_METHOD.PUSH, NOTIFICATION_TOPIC.CHAT_PRIVATE_MESSAGE)}
        {renderNotifOption(NOTIFICATION_METHOD.PUSH, NOTIFICATION_TOPIC.CHAT_GROUP_MESSAGE)}
        {renderNotifOption(NOTIFICATION_METHOD.PUSH, NOTIFICATION_TOPIC.ORDER_NEW)}
        {renderNotifOption(NOTIFICATION_METHOD.PUSH, NOTIFICATION_TOPIC.ORDER_ACCEPTED)}
        {renderNotifOption(NOTIFICATION_METHOD.PUSH, NOTIFICATION_TOPIC.ORDER_CANCELED)}
        {renderNotifOption(NOTIFICATION_METHOD.PUSH, NOTIFICATION_TOPIC.ORDER_COMMENT)}
        {renderNotifOption(NOTIFICATION_METHOD.PUSH, NOTIFICATION_TOPIC.ORDER_ATTACHMENT)}
      </S.Section>
    </FormSection>
  );

  function getNotificationValue(method: NOTIFICATION_METHOD, topic: NOTIFICATION_TOPIC): boolean {
    if (
      method === NOTIFICATION_METHOD.PUSH &&
      topic === NOTIFICATION_TOPIC.ALL &&
      workingStatus === WORKING_STATUS.NOT_AVAILABLE &&
      muteNotificationsWhileNotAvailable
    )
      return false;
    const exists = notifications[method] && notifications[method][topic] !== undefined;
    return exists ? notifications[method][topic] : true;
  }

  /**
   * Render message error with icon
   */
  function renderNotifOption(method: NOTIFICATION_METHOD, topic: NOTIFICATION_TOPIC) {
    const disabled = !getNotificationValue(method, NOTIFICATION_TOPIC.ALL);

    return (
      <S.Option>
        <S.Check
          isChecked={getNotificationValue(method, topic)}
          disabled={disabled}
          onClick={val => {
            updateMe([
              {
                n: 'settings',
                v: {
                  ...me.settings,
                  notifications: {
                    ...notifications,
                    [method]: {
                      ...(notifications[method] || {}),
                      [topic]: val,
                    },
                  },
                },
              },
            ]);
          }}
          size={20}
        />
        <S.OptionText disabled={disabled}>{getTopic(topic)}</S.OptionText>
      </S.Option>
    );
  }

  /**
   * Convert a topic into its literal
   * literals are not composed dinamically to protect deprecation processes
   */
  function getTopic(topic: NOTIFICATION_TOPIC): string {
    switch (topic) {
      case NOTIFICATION_TOPIC.CHAT_GROUP_MESSAGE:
        return __('Components.Settings.notifications.chat_group_message');
      case NOTIFICATION_TOPIC.CHAT_PRIVATE_MESSAGE:
        return __('Components.Settings.notifications.chat_private_message');
      case NOTIFICATION_TOPIC.ORDER_CANCELED:
        return __('Components.Settings.notifications.order_canceled');
      case NOTIFICATION_TOPIC.ORDER_COMMENT:
        return __('Components.Settings.notifications.order_comment');
      case NOTIFICATION_TOPIC.ORDER_NEW:
        return __('Components.Settings.notifications.order_new');
      case NOTIFICATION_TOPIC.ORDER_ACCEPTED:
        return __('Components.Settings.notifications.order_accepted');
      case NOTIFICATION_TOPIC.ORDER_ATTACHMENT:
        return __('Components.Settings.notifications.order_attachment');
    }
    return '';
  }
};

const Security = ({
  me,
  securityRef,
  show2FAModal,
}: {
  me: IUser;
  securityRef: React.RefObject<HTMLDivElement>;
  show2FAModal: (activate: boolean) => void;
}) => {
  const { country, phone } = me;
  const hasPhoneNumber = !!(country && phone);
  const phoneDisplay = hasPhoneNumber
    ? new AsYouType().input('+' + country + '' + (phone + '').replace(/\D/g, ''))
    : '';
  const twoFAEnabled = me.settings.twoFAEnabled;
  return (
    <FormSection
      id="security"
      title={__('Components.Settings.Menu.security.title')}
      sectionRef={securityRef}
      numHeaders={0}
    >
      <S.Section>
        <S.TwoFactorHeader>
          <S.SectionTitle>{__('Components.Settings.security.2fa')}</S.SectionTitle>
          <S.TwoFactorSwitch
            isChecked={twoFAEnabled}
            onChange={(_, value) => {
              show2FAModal(value);
            }}
          />
        </S.TwoFactorHeader>
      </S.Section>
      <S.SubtitleGrey2>
        <>
          <S.TextActive2FA activated={twoFAEnabled}>
            {(twoFAEnabled && hasPhoneNumber
              ? __('Components.Settings.security.activated')
              : __('Components.Settings.security.deactivated')) + ' '}
          </S.TextActive2FA>
          {utils.formatText(
            twoFAEnabled && hasPhoneNumber
              ? __('Components.Settings.security.subtitle_enabled')
              : phoneDisplay
              ? __('Components.Settings.security.subtitle_disabled', { phone: phoneDisplay })
              : __('Components.Settings.security.subtitle_disabled_no_phone'),
            str => (
              <S.TextSemiGrey2 key={str}>{str}</S.TextSemiGrey2>
            ),
          )}
        </>
      </S.SubtitleGrey2>
    </FormSection>
  );
};

const TwoFAModalComponent = props => <TwoFAModal {...props} isOpened={true} />;
