import {
  __,
  getNearestDeliveryDate,
  i18n,
  IOffer,
  IOfferedProduct,
  IReferenceResponse,
  IReferenceSellerView,
  notificationsActions,
  offerActions,
  referenceActions,
  RenderTrack,
  sellerWorkspaceActions,
  sellerWorkspaceSelectors,
  userSelectors,
  utils,
} from 'common-services';
import { camelCase, mapKeys, uniqBy } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, RouteComponentProps, useHistory, useLocation, useParams } from 'react-router-dom';

import config from '../../../../bindings/config';
import { ROUTE_PATHS } from '../../../constants';
import { logError } from '../../../services/log';
import getPath from '../../../util/routes';
import { ColumnContainer, LettersAvatar, Ribbon, SimpleDropdown } from '../../atoms';
import { BubbleColor } from '../../atoms/TagBubble/TagBubble.component';
import { SearchProductModal } from '../../molecules';
import InputEdit from '../../molecules/InputEdit';
import OfferedProductTable from '../../molecules/OfferedProductTable';
import Workspace from '../Workspace/Workspace.component';
import * as S from './WorkspaceOfferSeller.styled';

export type IRouteProps = RouteComponentProps<{
  workspaceId?: string;
  clientId?: string;
  history?: string;
}>;

export type IProps = IRouteProps;

const buildDefaultOfferName = (client: IClient, offerDate: Date): string => {
  return `${client?.company} (${client?.name}) ${Intl.DateTimeFormat(i18n.default.currentLocale(), {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(offerDate)}`;
};

const isOfferNameCustom = (offer: IOffer, client: IClient, offerDate: Date): boolean => {
  if (!offer || !offer.name || offer.name === '') {
    return false;
  }
  return offer.name !== buildDefaultOfferName(client, offerDate);
};

const WorkspaceOffers: React.FC<IProps> = ({ location }) => {
  const [t, _] = React.useState(Date.now());
  const history = useHistory();
  const dispatch = useDispatch<any>();
  const loc = useLocation<{ products: Array<GenericProduct>; clientId: number; prevOffer: IOffer }>();

  const params = useParams<{ workspaceId: string; offerId: string }>();
  const workspaceId = params.workspaceId;
  const offerId = params.offerId;

  const isNew = offerId === 'new' || loc.pathname.endsWith('/new');
  const isRepeated = loc.pathname.endsWith('/repeat');

  // New offer creation init
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [offerDate, setOfferDate] = React.useState<Date>(tomorrow);
  const [customOfferName, setCustomOfferName] = React.useState<boolean>(false);

  const me = useSelector(userSelectors.getUser);
  const catalog = useSelector(sellerWorkspaceSelectors.getCatalog(Number(workspaceId)));

  const [offer, setOffer] = React.useState<IOffer>({} as any as IOffer);
  const [isEditable, setIsEditable] = React.useState<boolean>(isNew);
  const [isPublishable, setIsPublishable] = React.useState<boolean>(isNew);
  const [selectedClientId, setSelectedClientId] = React.useState<number>(0);
  const [selectedAddresses, setSelectedAddresses] = React.useState<Array<IAddress>>([{ id: -1 } as any as IAddress]); // Address with id === -1 means no Address has been selected yet
  const [clientAddresses, setClientAddresses] = React.useState<Array<IAddress>>([]);

  const [selectedProducts, setSelectedProducts] = React.useState<Array<GenericProduct>>([]);
  const [showSearchProductModal, setShowSearchProductModal] = React.useState(false);
  const [hasChanges, setHasChanges] = React.useState(false);
  const [clientAssociations, setClientAssociations] = React.useState<Array<IReferenceSellerView>>([]);

  const [references, setReferences] = React.useState<Array<IReferenceResponse>>([]);
  const [loadingReferences, setLoadingReferences] = React.useState<boolean>(false);

  const [hasReferenceConflicts, setHasReferenceConflicts] = React.useState<boolean>(false);
  const [hasMissingAssociations, setHasMissingAssociations] = React.useState<boolean>(false);
  React.useEffect(() => {
    dispatch(sellerWorkspaceActions.clientsGet(me.id, Number(workspaceId)));
  }, []);

  const toggleCantRepeatNorDraftNotHistory = useSelector(
    userSelectors.hasToggleEnabled(config.TOGGLE_CANT_REPEAT_NOR_DRAFT_NOR_HISTORY),
  );

  const selectNewProducts = (products: Array<GenericProduct>) => {
    // @ts-ignore
    const newOfferedProducts: Array<IOfferedProduct> = selectedAddresses.flatMap(address => {
      return products
        .filter(p =>
          offer.offeredProducts
            ? offer.offeredProducts.find(op => {
                if (op.sku === p.sku) {
                  return op;
                }
              })
              ? false
              : true
            : true,
        )
        .map(product => {
          return {
            sku: product.sku,
            offeredPrice: product.price,
            offeredQuantity: 0,
            offeredQuantityUnit: product.saleUnit,
            warehouseId: address.id === -1 ? 0 : address.id,
            unlimitedQuantity: false,
            offerId: offer.id,
            priceUnit: product.priceUnit,
            deliveryDate: offerDate.getTime(),
          };
        });
    });
    setOffer({
      ...offer,
      offeredProducts: offer.offeredProducts ? offer.offeredProducts.concat(newOfferedProducts) : newOfferedProducts,
    });
    setSelectedProducts(uniqBy(selectedProducts.concat(products), 'sku'));
  };

  const client = useSelector(
    sellerWorkspaceSelectors.getClient(Number(workspaceId), isNew ? selectedClientId : Number(offer.buyerId)),
  );

  // setSelectedClientId on offer.buyerId change
  React.useEffect(() => {
    if (offer.buyerId) {
      setSelectedClientId(Number(offer.buyerId));
    }
  }, [offer.buyerId]);

  // Address functions
  const areAddressesNotSet = (): boolean => {
    return selectedAddresses.length === 0 || (selectedAddresses.length === 1 && selectedAddresses[0].id === -1);
  };

  const updateSelectedAddresses = (existingAddresses: Array<IAddress>, newAddresses: Array<IAddress>) => {
    // Keep only the addresses that are not already present
    const addressesToAdd = newAddresses.filter(address => !existingAddresses.find(a => a.id === address.id));
    setSelectedAddresses([...existingAddresses, ...addressesToAdd]);
    updateOfferedProductsAddingAddresses(addressesToAdd);
  };

  const removeAddress = (id: number) => {
    const newAddresses = selectedAddresses.filter(address => address.id !== id);
    if (newAddresses.length === 0) {
      updateSelectedAddresses([], [{ id: -1 } as any as IAddress]);
    } else {
      setSelectedAddresses(newAddresses);
      setOffer({ ...offer, offeredProducts: offer.offeredProducts.filter(p => p.warehouseId !== id) });
    }
  };

  const addAddress = (address: IAddress) => {
    addAddresses([address]);
  };

  const addAddresses = (addresses: Array<IAddress>) => {
    if (areAddressesNotSet()) {
      updateSelectedAddresses([], addresses);
    } else {
      updateSelectedAddresses(selectedAddresses, addresses);
    }
  };

  const addAddressesByIds = (ids: Array<number>) => {
    // Find addresses by id
    const addresses = clientAddresses.filter(address => ids.includes(address.id));
    if (addresses.length > 0) {
      addAddresses(addresses);
    }
  };

  // Product functions

  const deleteProduct = (sku: string) => {
    setOffer({ ...offer, offeredProducts: offer.offeredProducts.filter(p => p.sku !== sku) });
    setSelectedProducts(
      uniqBy(
        selectedProducts.filter(p => p.sku !== sku),
        'sku',
      ),
    );
  };

  // Convert offered product to product
  const offeredProductToProduct = (offeredProduct: IOfferedProduct): GenericProduct => {
    if (offeredProduct.productSnapshot) {
      const res = {
        ...mapKeys(offeredProduct.productSnapshot, (v, k) => camelCase(k)),
        saleUnit: offeredProduct.offeredQuantityUnit,
        priceUnit: offeredProduct.priceUnit,
      };
      if (offeredProduct.productSnapshot.type) {
        if (offeredProduct.productSnapshot.type.type && offeredProduct.productSnapshot.type.variety) {
          if (offeredProduct.productSnapshot.productTitle === '') {
            res.productTitle = `${offeredProduct.productSnapshot.type.type} ${offeredProduct.productSnapshot.type.variety}`;
          }
          res.type = offeredProduct.productSnapshot.type.type;
          res.variety = offeredProduct.productSnapshot.type.variety;
        }
        if (offeredProduct.productSnapshot.type.origin) {
          res.origin = offeredProduct.productSnapshot.type.origin;
        }
      }
      return res as GenericProduct;
    } else {
      return {
        sku: offeredProduct.sku,
        saleUnit: offeredProduct.offeredQuantityUnit,
        priceUnit: offeredProduct.priceUnit,
      } as any as GenericProduct;
    }
  };

  // Offer initialization
  React.useEffect(() => {
    if (isRepeated) {
      dispatch(
        offerActions.offerGet(Number(workspaceId), Number(loc.state.prevOffer.id), off => {
          if (off) {
            // Remove off.id and off.offeredProducts.offer_id
            const newOfferedProducts = off.offeredProducts.map(p => {
              return { ...p, id: undefined, offerId: undefined };
            });

            setOffer({
              ...off,
              id: undefined,
              status: 'Draft',
              updatedAt: new Date().getTime(),
              name: buildDefaultOfferName(client, offerDate),
              offeredProducts: newOfferedProducts,
            });

            setIsEditable(true);
            setIsPublishable(true);
            setOfferDate(tomorrow);
            // setSelectedProducts for distinct off.offeredProducts.product_id
            const distinctProducts = newOfferedProducts.map(p => p.productId).filter((v, i, a) => a.indexOf(v) === i);
            setSelectedProducts(
              uniqBy(
                distinctProducts.map(productId => {
                  const product = off.offeredProducts.find(p => p.productId === productId);
                  return offeredProductToProduct(product);
                }),
                'sku',
              ),
            );
          }
        }),
      );
      setHasChanges(true);
    } else if (!isNew) {
      dispatch(
        offerActions.offerGet(Number(workspaceId), Number(offerId), off => {
          if (off) {
            setOffer(off);

            if (['Draft', 'CounterOffered'].includes(off.status)) {
              setIsEditable(true);
              setIsPublishable(true);
            }
            if (off && off.offeredProducts.length > 0) {
              const nearestDate = getNearestDeliveryDate(off);
              if (nearestDate && nearestDate !== 0) {
                setOfferDate(new Date(nearestDate));
              } else {
                setOfferDate(tomorrow);
              }
            }
            setCustomOfferName(isOfferNameCustom(offer, client, offerDate));
            // setSelectedProducts for distinct off.offeredProducts.product_id
            const distinctProducts = off.offeredProducts.map(p => p.productId).filter((v, i, a) => a.indexOf(v) === i);
            setSelectedProducts(
              uniqBy(
                distinctProducts.map(productId => {
                  const product = off.offeredProducts.find(p => p.productId === productId);
                  return offeredProductToProduct(product);
                }),
                'sku',
              ),
            );
          }
        }),
      );
    } else {
      if (loc.state && loc.state.products && loc.state.products.length > 0) {
        selectNewProducts(loc.state.products);
      } else {
        history.push(
          getPath({
            path: ROUTE_PATHS.WORKSPACE_OFFERS,
            workspaceId,
          }),
        );
      }
      if (loc.state && loc.state.clientId) {
        setSelectedClientId(loc.state.clientId);
        setOffer(prevOffer => ({
          ...prevOffer,
          name: buildDefaultOfferName(client, offerDate),
          authorId: me?.id,
          status: 'Draft',
          deliveryDate: tomorrow.getTime(),
          updatedAt: new Date().getTime(),
          buyerId: loc.state.clientId,
        }));
      } else {
        history.push(
          getPath({
            path: ROUTE_PATHS.WORKSPACE_OFFERS,
            workspaceId,
          }),
        );
      }
    }
  }, [workspaceId, offerId, client]);
  // Address selection
  const [areAddressesSetup, setAreAddressesSetup] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (!areAddressesSetup && offer && offer.offeredProducts && clientAddresses && clientAddresses.length > 0) {
      // Iterate on offered product to add distinct addresses by id
      const distinctAddresses = offer.offeredProducts.reduce((acc, product) => {
        if (product.warehouseId !== 0 && !acc.find(i => i === product.warehouseId)) {
          acc.push(product.warehouseId);
        }
        return acc;
      }, [] as Array<number>);
      if (distinctAddresses.length > 0) {
        addAddressesByIds(distinctAddresses);
        setAreAddressesSetup(true);
      }
    }
  }, [offer, clientAddresses]);

  // Offer update on address addition
  const updateOfferedProductsAddingAddresses = (addresses: Array<IAddress>) => {
    if (offer.offeredProducts) {
      // If the added address is already present in offered product, skip
      const newAddresses = addresses.filter(address => !offer.offeredProducts.find(p => p.warehouseId === address.id));
      if (newAddresses.length === 0) {
        return;
      }
      const hasNoAddress = newAddresses.filter(address => address.id === -1).length > 0;

      // Add an address copying values
      const lastOfferedProductWarehouseId = offer.offeredProducts[offer.offeredProducts.length - 1].warehouseId;
      const newOfferedProducts = newAddresses.flatMap(address => {
        return offer.offeredProducts.map(offeredProduct => {
          if (offeredProduct.warehouseId === lastOfferedProductWarehouseId) {
            return {
              ...offeredProduct,
              warehouseId: address.id === -1 ? 0 : address.id,
            };
          }
        });
      });

      // Concat the new and old offeredProduct
      setOffer({
        ...offer,
        offeredProducts: [...offer.offeredProducts, ...newOfferedProducts].filter(o =>
          o ? (hasNoAddress ? o.warehouseId === 0 : o.warehouseId !== 0) : false,
        ),
      });
    }
  };

  // Buyer address fetch
  React.useEffect(() => {
    if (client) {
      dispatch(
        sellerWorkspaceActions.buyerAddressesGet(Number(workspaceId), client.userId, (adds: Array<IAddress>) => {
          setClientAddresses(adds);
        }),
      );
    }
  }, [client]);

  // Custom offer name change
  React.useEffect(() => {
    if (client) {
      setCustomOfferName(isOfferNameCustom(offer, client, offerDate));
    }
  }, [offer.name, client]);

  // Offer date change
  React.useEffect(() => {
    if (offer && offer.offeredProducts) {
      setOffer({
        ...offer,
        name: customOfferName ? offer.name : buildDefaultOfferName(client, offerDate),
        deliveryDate: offerDate.getTime(),
        offeredProducts: offer.offeredProducts.map(p => ({ ...p, deliveryDate: offerDate.getTime() })),
      });
    }
  }, [offer.name, offerDate, client]);

  // set repeated elements when there are more than one SKUs linked with the same Reference
  const [repeatedElements, setRepeatedElements] = React.useState<any>([]);

  React.useEffect(() => {
    if (client?.buyerWorkspaceId) {
      dispatch(
        referenceActions.getAssociatedReferences(Number(workspaceId), client.buyerWorkspaceId, (res: any) => {
          setClientAssociations(res);
        }),
      );
    }
  }, [client?.buyerWorkspaceId]);

  // Set an offeredProduct by SKU into the Offer
  const setProductOffer = (sku: string, name: string, value: number | string | boolean, warehouseId?: number) => {
    const offeredProducts = offer.offeredProducts.map(offeredProduct => {
      if (offeredProduct.sku === sku && offeredProduct.warehouseId === warehouseId) {
        return { ...offeredProduct, [name]: value };
      } else if (offeredProduct.sku === sku && !warehouseId) {
        return { ...offeredProduct, [name]: value };
      }
      return offeredProduct;
    });
    setOffer({ ...offer, offeredProducts });
  };

  // Change offered products duplicate ids
  const changeOfferedProductsDuplicateIds = (off: IOffer): IOffer => {
    const seenProductIds = new Set<number>();
    return {
      ...offer,
      offeredProducts: offer.offeredProducts.map(product => {
        if (product.id) {
          if (seenProductIds.has(product.id)) {
            return { ...product, id: undefined };
          }
          seenProductIds.add(product.id);
        }
        return product;
      }),
    };
  };

  // API async Offer upsert function
  const upsertOffer = async (): Promise<IOffer> => {
    setHasChanges(false);
    if (isNew || isRepeated) {
      return new Promise<IOffer>((resolve, reject) => {
        dispatch(
          offerActions.offerCreate(Number(workspaceId), offer, (off: IOffer) => {
            setOffer(off);
            resolve(off);
            history.push(
              getPath({
                path: ROUTE_PATHS.WORKSPACE_OFFER_EDIT,
                workspaceId: String(workspaceId),
                offerId: String(off.id),
              }),
            );
          }),
        );
      });
    } else {
      if (isEditable) {
        const req = changeOfferedProductsDuplicateIds(offer);
        return new Promise<IOffer>((resolve, reject) => {
          dispatch(
            offerActions.offerUpdate(Number(workspaceId), req, (off: IOffer) => {
              setOffer(off);
              resolve(off);
            }),
          );
        });
      }
    }
  };

  const openSearchProductsModal = () => {
    return (
      <SearchProductModal
        amSeller={true}
        cartClean={undefined}
        catalog={catalog}
        close={() => setShowSearchProductModal(false)}
        contactId={Number(isNew ? selectedClientId : Number(offer.buyerId))}
        featured={{}}
        history={history}
        me={me}
        name={''}
        onSubmitSelectedProducts={async newSelections => {
          selectNewProducts(newSelections);
          await setShowSearchProductModal(false);
          setHasChanges(true);
        }}
        priceMode={'read'}
        selectMode="offers"
        showFeatured={false}
        status="active"
        location={location}
        prices={{}}
      />
    );
  };

  React.useEffect(() => {
    RenderTrack.track('WorkspaceOfferSeller', {
      renderTime: t,
    });
  }, []);

  const removeAssociation = (referenceId, sku) => {
    dispatch(
      referenceActions.deleteReferenceProduct(client.buyerWorkspaceId, referenceId, {
        sku,
        sellerWorkspaceId: +workspaceId,
      }),
    );
    setClientAssociations(clientAssociations.filter(ca => ca.sku !== sku));
  };

  const associateProduct = async (referenceId, sku) => {
    await dispatch(
      referenceActions.associateProduct(client.buyerWorkspaceId, referenceId, {
        sku,
        sellerWorkspaceId: +workspaceId,
      }),
    );
    dispatch(
      referenceActions.getAssociatedReferences(Number(workspaceId), client?.buyerWorkspaceId, (res: any) => {
        setClientAssociations(res);
      }),
    );
  };

  React.useEffect(() => {
    if (workspaceId && client?.buyerWorkspaceId) {
      setLoadingReferences(true);
      dispatch(
        referenceActions.getReferences(client.buyerWorkspaceId, res => {
          setReferences(res);
          setLoadingReferences(false);
        }),
      );
    }
  }, [workspaceId, client?.buyerWorkspaceId]);

  return (
    <Workspace
      subtitle={''}
      title={offer && offer.name !== '' ? offer.name : 'Offer'}
      tabSelected={'offers'}
      workspaceId={Number(workspaceId)}
      isBuyerWorkspace={false}
      parentSections={[
        {
          label: __('Components.ProductsList.Tabs.offers'),
          action: () => {
            history.push(
              getPath({
                path: ROUTE_PATHS.WORKSPACE_OFFERS,
                workspaceId,
              }),
            );
          },
        },
      ]}
    >
      <Prompt
        when={hasChanges && !showSearchProductModal}
        message={__('Components.ProductDetails.confirm_exit_changes')}
      />
      {hasMissingAssociations ? <Ribbon text={__('Components.OffersList.ribbon')} type={'warning'} /> : null}
      <S.WsWrapper>
        <S.HeaderDashboards>
          <S.HeaderRow>
            <S.LeftRow>
              <InputEdit
                editable={isEditable}
                value={offer.name}
                onSave={value => {
                  setCustomOfferName(true);
                  setOffer({ ...offer, name: value });
                  setHasChanges(true);
                }}
                name="offer_name"
              />
            </S.LeftRow>
            <S.RightCtaRow>
              {isEditable ? (
                <S.CtaButton
                  type="secondary"
                  disabled={!hasChanges}
                  onClick={async () => {
                    await upsertOffer();
                    dispatch(
                      notificationsActions.notificationShow({
                        title: __('Components.OffersList.notification.draft'),
                        subtitle: __('Components.OffersList.notification.description'),
                        closable: true,
                        style: 'success',
                      }),
                    );
                    setHasChanges(false);
                  }}
                >
                  {__('Components.OffersList.save')}
                </S.CtaButton>
              ) : null}

              {isPublishable && !hasReferenceConflicts ? (
                <S.GreenButton
                  type="principal"
                  disabled={hasMissingAssociations}
                  onClick={async () => {
                    setHasChanges(false);
                    try {
                      upsertOffer().then(async off => {
                        if (off && off.id) {
                          await dispatch(offerActions.offerPublish(Number(workspaceId), off.id, offer.name))
                            .then(() => {
                              history.push(
                                getPath({
                                  path: ROUTE_PATHS.WORKSPACE_OFFERS,
                                  workspaceId: String(workspaceId),
                                }),
                              );
                            })
                            .then(() => {
                              dispatch(
                                notificationsActions.notificationShow({
                                  title: __('Components.OffersList.notification.share'),
                                  subtitle: __('Components.OffersList.notification.description'),
                                  closable: true,
                                  style: 'success',
                                }),
                              );
                            });
                        }
                      });
                    } catch (error) {
                      logError(error, 'share offer');
                    }
                  }}
                >
                  {__('Components.OffersList.share')}{' '}
                </S.GreenButton>
              ) : null}
              {config.TOGGLE_REPEAT_OFFER.enabled && offer.id ? (
                offer.status === 'Draft' ? (
                  <SimpleDropdown
                    hAlign="right"
                    options={[
                      { key: 'repeat', value: __('Components.OffersList.repeat') },
                      // TODO implement delete offer
                      // { key: 'delete', value: __('Components.OffersList.delete') },
                    ]}
                    onSelect={async () => {
                      await history.push(
                        getPath({
                          path: ROUTE_PATHS.WORKSPACE_OFFER_EDIT,
                          workspaceId,
                          offerId: 'repeat',
                        }),
                        {
                          prevOffer: offer,
                        },
                      );
                      dispatch(
                        notificationsActions.notificationShow({
                          title: __('Components.OffersList.notification.repeat'),
                          subtitle: __('Components.OffersList.notification.description'),
                          closable: true,
                          style: 'success',
                        }),
                      );
                    }}
                  >
                    <S.KebabIconContainer>
                      <S.KebabIcon name="Kebab" />
                    </S.KebabIconContainer>
                  </SimpleDropdown>
                ) : (
                  <S.CtaButton
                    type="secondary"
                    onClick={async () => {
                      await history.push(
                        getPath({
                          path: ROUTE_PATHS.WORKSPACE_OFFER_EDIT,
                          workspaceId,
                          offerId: 'repeat',
                        }),
                        {
                          prevOffer: offer,
                        },
                      );
                      dispatch(
                        notificationsActions.notificationShow({
                          title: __('Components.OffersList.notification.repeat'),
                          subtitle: __('Components.OffersList.notification.description'),
                          closable: true,
                          style: 'success',
                        }),
                      );
                    }}
                    disabled={toggleCantRepeatNorDraftNotHistory}
                  >
                    {__('Components.OffersList.repeat')}
                  </S.CtaButton>
                )
              ) : null}
            </S.RightCtaRow>
          </S.HeaderRow>
          <S.SubHeaderRow>
            <S.StatusTag
              label={__('Components.OffersList.states.' + offer.status?.toLowerCase() + '.name')}
              disabled={true}
              color={__('Components.OffersList.states.' + offer.status?.toLowerCase() + '.color') as BubbleColor}
            />
          </S.SubHeaderRow>
        </S.HeaderDashboards>
        <S.InfoRow>
          <ColumnContainer>
            <S.FieldText> {utils.firstToUpperCase(__('Global.Commons.client', { count: 1 }))}</S.FieldText>
            <S.ClientRow>
              <LettersAvatar
                img={client?.avatar}
                size={32}
                text={client?.name}
                avatarColor={utils.getAvatarColor(client?.company || '-')}
              />
              <S.ClientNameColumn>
                <S.TextBold>{client?.company ? client.company : null}</S.TextBold>
                <S.TextBlack>{client?.name || '-'}</S.TextBlack>
              </S.ClientNameColumn>
            </S.ClientRow>
          </ColumnContainer>
          <ColumnContainer>
            <S.FieldText> {utils.firstToUpperCase(__('Global.Commons.deliveryDate', { count: 1 }))}</S.FieldText>
            <S.DatePicketWrapper>
              <S.DatePicker
                clearable={false}
                editable={isEditable}
                id="offer-date"
                className={'offer-dp'}
                minDate={new Date()}
                initDate={offerDate}
                onDateChange={newDate => {
                  setOfferDate(new Date(newDate));
                  setHasChanges(true);
                }}
              />
            </S.DatePicketWrapper>
          </ColumnContainer>
        </S.InfoRow>
        {showSearchProductModal ? openSearchProductsModal() : null}
        <OfferedProductTable
          addAddress={addAddress}
          addresses={selectedAddresses}
          clientAssociations={clientAssociations}
          client={client}
          deleteProduct={deleteProduct}
          editable={isEditable}
          offeredProducts={offer.offeredProducts}
          products={selectedProducts}
          setProductOffer={setProductOffer}
          searchProducts={() => {
            setHasChanges(false);
            setShowSearchProductModal(true);
          }}
          removeAddress={removeAddress}
          references={references}
          loadingReferences={loadingReferences}
          setHasChanges={() => setHasChanges(true)}
          setHasReferenceConflicts={setHasReferenceConflicts}
          setHasMissingAssociations={setHasMissingAssociations}
          totalAddresses={clientAddresses}
          workspaceId={workspaceId}
          removeAssociation={removeAssociation}
          associateProduct={associateProduct}
        />
      </S.WsWrapper>
    </Workspace>
  );
};

export default React.memo(WorkspaceOffers);
