import { __, utils } from 'common-services';
import * as React from 'react';

import { ContactItemSelectable } from '..';
import * as S from './FormatsModal.styled';

export interface IProps {
  close: () => void;
  formats: IFormatsResponse;
  initSelectedFormat?: string;
  onSubmit: (format: IFormat) => void;
}

const FormatsModal: React.FC<IProps> = ({ close, formats, initSelectedFormat, onSubmit }) => {
  const [filter, setFilter] = React.useState<string>('');
  const [selectedFormat, setSelectedFormat] = React.useState<IFormat>();
  React.useEffect(() => {
    if (initSelectedFormat) {
      const format = formats.all.find(f => f.id === initSelectedFormat);
      if (format) setSelectedFormat(format);
    }
  }, [initSelectedFormat, formats.all]);

  /**
   * Render formats list
   */
  function renderFormatList() {
    const f = filter && filter.length > 1 && utils.toLocaleLowerCaseNormalized(filter);
    const formatsToShow = formats.all.filter(
      format =>
        !f ||
        utils.toLocaleLowerCaseNormalized(format.label).includes(f) ||
        utils.toLocaleLowerCaseNormalized(format.id).includes(f),
    );

    const formatsOrganized = formatsToShow.reduce(
      (acc, format) => {
        if (formats.recent.includes(format.id)) {
          acc.recent.push(format);
        } else if (formats.recommended.includes(format.id)) {
          acc.recommended.push(format);
        } else {
          acc.all.push(format);
        }
        return acc;
      },
      { all: [], recommended: [], recent: [] },
    );

    return (
      <S.Scroll className="formats-modal-scroll">
        {formatsOrganized.recent.length ? (
          <S.FlexCol>
            <S.SectionTitle>{__('FormatsModal.recent')}</S.SectionTitle>
            {formatsOrganized.recent.map((format, index, arr) =>
              renderFormatItem(format, index, index === arr.length - 1),
            )}
          </S.FlexCol>
        ) : null}
        {formatsOrganized.recommended.length ? (
          <S.FlexCol>
            <S.SectionTitle>{__('FormatsModal.recommended')}</S.SectionTitle>
            {formatsOrganized.recommended.map((format, index, arr) =>
              renderFormatItem(format, index, index === arr.length - 1),
            )}
          </S.FlexCol>
        ) : null}
        {formatsOrganized.all.length ? (
          <S.FlexCol>
            <S.SectionTitle>{__('FormatsModal.all')}</S.SectionTitle>
            {formatsOrganized.all.map((format, index, arr) =>
              renderFormatItem(format, index, index === arr.length - 1),
            )}
          </S.FlexCol>
        ) : null}
      </S.Scroll>
    );
  }

  /**
   * Render selectable format item
   */
  function renderFormatItem(format: IFormat, index: number, isLast: boolean) {
    const { id, logo, label } = format;
    const isSelected = selectedFormat?.id === id;
    return (
      <ContactItemSelectable
        key={index + '-' + id}
        avatar={logo}
        avatarColor={utils.getAvatarColor(label)}
        isAdmin={false}
        isLast={isLast}
        isRadio={true}
        selected={isSelected}
        onPress={() => {
          setSelectedFormat(isSelected ? undefined : format);
        }}
        self={false}
        name={label}
      />
    );
  }

  /**
   * On next action, submit format selected
   */
  const onNext = React.useCallback(() => {
    if (selectedFormat) {
      close();
      onSubmit(selectedFormat);
    }
  }, [close, onSubmit, selectedFormat]);

  return (
    <S.ActionsModal onClose={close} title={__('FormatsModal.title')} subtitle={__('FormatsModal.subtitle')}>
      <S.Search
        onChange={s => setFilter(s)}
        placeHolder={__('ProductListShare.search_placeholder')}
        id="input_search_product_share"
      />
      {renderFormatList()}
      <S.FooterRow>
        <S.CTA id="button-cancel" onClick={close} type="secondary">
          {__('FormatsModal.cancel')}
        </S.CTA>
        <S.CTA id="button-add-format" onClick={onNext} type="principal">
          {__('FormatsModal.add')}
        </S.CTA>
      </S.FooterRow>
    </S.ActionsModal>
  );
};

export default React.memo(FormatsModal);
